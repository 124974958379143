import { http } from "./index"
import { loadUrl } from "../shoppinga-client-common/Network"
import { Shop } from "@Common/Models/Shop"
import { ShopBranch } from "../shoppinga-common/Models/Offer"

export type GetShopsResponse = {
	shops: Shop[]
}

export const getShops = (query: string) => {
	const url = loadUrl('shops', {
		query
	})
	return http<GetShopsResponse>(url)
}

type GetShopBranchesResponse = {
	shop_branches: ShopBranch[]
}
export const getShopBranches = (shop_id: number, query: string, limit?: number, offset?: number) => {
	return http<GetShopBranchesResponse>(loadUrl(`shop_branches`, {
		shop_id,
		query,
		limit,
		offset
	}))
}

export const getOfferExcludedShopBranches = (offer_id: number) => {
	return http<GetShopBranchesResponse>(`offers/${offer_id}/excluded-shop-branches`)
}