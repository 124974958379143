import { http } from "."
import { loadUrl } from "../shoppinga-client-common/Network"
import { ID } from "../shoppinga-common/Models/Generic"
import { Inspiration } from "../shoppinga-common/Models/Inspiration"

export type GetInspirationsParams = {
	published?: boolean
	pending?: boolean
	favourites?: boolean
	user_id?: ID
	limit?: number
	offset?: number
	query?: string
}
export type GetInspirationsResponse = {
	inspirations: Inspiration[]
	rows?: number
	count?: number
}
export const getInspirations = (params: GetInspirationsParams) => {
	const url = loadUrl("inspirations", params)
	return http<GetInspirationsResponse>(url)
}

export type InspirationResponse = {
	inspiration: Inspiration
}

export const createInspiration = (data: Partial<Inspiration>) => {
	return http<InspirationResponse>("inspirations", "POST", data)
}

export const updateInspiration = (inspiration_id: ID, data: Partial<Inspiration>) => {
	return http<InspirationResponse>(`inspirations/${inspiration_id}`, 'PUT', data)
}

export const deleteInspiration = (inspiration_id: ID) => {
	return http<any>(`inspirations/${inspiration_id}`, 'DELETE')
}

export const deleteInspirationsBulk = (inspiration_ids: ID[]) => {
	const url = loadUrl('inspirations/bulk', {
		ids: inspiration_ids.join(',')
	})
	return http<any>(url, 'DELETE')
}

export const getInspiration = (inspiration_id: ID, user_id?: ID) => {
	const url = loadUrl(`inspirations/${inspiration_id}`, { user_id })
	return http<InspirationResponse>(url)
}