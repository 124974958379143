import { colors } from '../../shoppinga-client-common/util'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React, { ReactNode } from 'react'
import { modalTitle } from '../../Global/styles';

type Props = {
	open: boolean
	onClose?: () => void
	children?: ReactNode
	title?: string
	actions: {
		label: string
		onPress: () => void,
		destructive?: boolean
		loading?: boolean
		disabled?: boolean
	}[]
}

const CModal = (props: Props) => {
	return (
		<Modal
			open={props.open}
			onClose={() => props.onClose?.()}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={{
				position: 'absolute' as 'absolute',
				top: '50%',
				left: '50%',
				width: 500,
				//@ts-ignore
				transform: 'translate(-50%, -50%)',
				bgcolor: 'background.paper',
				padding: "20px",
				borderRadius: "5px",
				maxHeight: "80%",
				display: 'flex'
			}}>
				<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
					<div style={{ ...modalTitle }}>{props.title}</div>
					<div style={{ flex: 1, overflow: 'auto' }}>
						{props.children}
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 20 }}>
						{props.actions.map((a, i) => a.loading ?
							<div key={i} style={{ minWidth: 80, display: 'flex', justifyContent: 'center' }}>
								<CircularProgress size={20} />
							</div>
							: (
								<Button disabled={a.disabled} style={{ minWidth: 80 }} key={i} onClick={a.onPress}>
									<div style={{ color: a.destructive ? colors.red : undefined, fontWeight: 'bold', margin: 5 }}>
										{a.label}
									</div>
								</Button>
							))}
					</div>
				</div>
			</Box>
		</Modal>
	)
}

export default CModal
