import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { login, saveSessionInfo } from '@Network/auth';
import { genericError, GENERIC_ERROR_MESSAGE, ItemsMap, successMessage } from '@Global/util';
import CButton from '@Components/CButton';
import CTextField from '@Components/CTextField';
import { useHistory } from 'react-router-dom';
import { modalActionsContainer, modalContainer, modalTitle, rootView } from '@Global/styles';
import { webPath } from 'src/App';
import { displayProductName, Product, ProductStatus, statusString } from '@Common/Models/Product';
import { approveProduct, deleteProduct, deleteProductsBulk, getProducts, rejectProduct } from '../Network/products';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Search } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import { usePagination } from '../Global/hooks/pagination.hook';
import config from '../config';
import Modal from '@material-ui/core/Modal';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { ID } from '../shoppinga-common/Models/Generic';
import { useToasts } from 'react-toast-notifications';

type Props = {
	mode: 'list' | 'pending'
}

const ProductsPage = (props: Props) => {

	const { addToast } = useToasts();

	const history = useHistory()
	const self: {
		query: string
	} = useMemo(() => ({
		query: "",
	}), [])

	const [data, setData] = useState<Product[]>([])
	const [searchText, setSearchText] = useState("")
	const pagination = usePagination()
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
	const [deletingProduct, setDeletingProduct] = useState<Product | null>(null)
	const [selectedProducts, setSelectedProducts] = useState<ID[]>([])

	const fetchData = useCallback(async (rows: number, page: number, query: string) => {
		const response = await getProducts({
			generic_first: true,
			offset: rows * page,
			limit: rows,
			query,
			status: props.mode == 'pending' ? [ProductStatus.Pending] : [ProductStatus.Approved]
		})
		if (response.success) {
			setData(response.data?.products || [])
			pagination.set({
				count: response.data?.count || 0,
				rows: response.data?.rows || 0
			})
		} else {
			genericError(addToast)
		}
	}, [pagination, props.mode])

	useEffect(() => {
		fetchData(20, 0, "")
		setSearchText("")
	}, [props.mode])

	const onSearch = useCallback((text: string) => {
		self.query = text
		setTimeout(() => {
			if (text == self.query) {
				setSearchText(text)
				pagination.setPage(0)
				fetchData(0, 0, text)
			}
		}, 500)
	}, [self, props.mode])

	const onPageChange = useCallback((e, newPage: number) => {
		pagination.setPage(newPage)
		fetchData(pagination.rows, newPage, searchText)
	}, [pagination, searchText, props.mode])

	const onDeleteClick = useCallback((product: Product) => {
		setDeletingProduct(product)
		setDeleteConfirmOpen(true)
	}, [props.mode])

	const onDeleteBulkClick = useCallback(() => {
		setDeleteConfirmOpen(true)
	}, [])

	const onOpenPress = useCallback((product: Product) => {
		//window.open(config.web_app + webPath('/admin/products/' + (props.mode == 'pending' ? "pending/" : "") + product.id), "_blank")
		history.push(webPath('/admin/products/' + (props.mode == 'pending' ? "pending/" : "") + product.id))
	}, [props.mode])

	const onNewProduct = useCallback(() => {
		//window.open(config.web_app + webPath('/admin/products/new'), "_blank")
		history.push(webPath('/admin/products/new'))
	}, [props.mode])

	const onDeleteConfirm = useCallback(async () => {
		let response
		if(selectedProducts.length > 0) {
			response = await deleteProductsBulk(selectedProducts)
		} else {
			response = await deleteProduct(deletingProduct!.id)
		}

		if (response.success) {
			successMessage("Prodotto/i eliminato/i con successo", addToast)
			setDeleteConfirmOpen(false)
			fetchData(pagination.rows, pagination.page, searchText)
		} else {
			genericError(addToast)
		}
	}, [deletingProduct, selectedProducts, pagination, searchText])

	const onDuplicateClick = useCallback((product: Product) => {
		//window.open(config.web_app + webPath('/admin/products/new?copy_from=' + product.id), "_blank")
		history.push(webPath('/admin/products/new?copy_from=' + product.id))
	}, [pagination, searchText])

	const approveProductPress = useCallback((product: Product) => {
		editProductStatus(product, ProductStatus.Approved)
	}, [pagination, searchText])

	const rejectProductPress = useCallback((product: Product) => {
		editProductStatus(product, ProductStatus.Rejected)
	}, [pagination, searchText])

	const editProductStatus = useCallback(async (product: Product, status: ProductStatus) => {
		let response;
		if (status == ProductStatus.Approved) {
			response = await approveProduct(product.id)
		} else {
			response = await rejectProduct(product.id)
		}

		if (response.success) {
			successMessage("Operazione completata", addToast)
			fetchData(pagination.rows, pagination.page, searchText)
		} else {
			genericError(addToast)
		}
	}, [pagination, searchText])

	const onProductChecked = useCallback((product: Product, checked: boolean) => {
		if (checked) {
			if (!selectedProducts.includes(product.id)) {
				setSelectedProducts(selectedProducts.concat([product.id]))
			}
		} else {
			setSelectedProducts(selectedProducts.filter((p) => {
				return p != product.id
			}))
		}
	}, [self, selectedProducts])

	return (
		<div style={{ ...rootView, padding: 20 }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<CTextField style={{ width: 400 }} icon={<Search />} placeholder={"Cerca tramite nome o categoria"} onChange={onSearch} />
				<div>
					{props.mode == 'list' && selectedProducts.length > 0 && <CButton style={{ marginRight: 10 }} label="Elimina selezionati" onClick={onDeleteBulkClick} />}
					{props.mode == 'list' && <CButton style={{ marginRight: 50 }} label="Aggiungi prodotto" onClick={onNewProduct} />}
				</div>
			</div>
			<TableContainer style={{ marginTop: 20 }} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							{props.mode == 'list' && <TableCell />}
							<TableCell style={{ fontWeight: 'bold' }}>Foto</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>EAN</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Brand</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Formato</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Categorie</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Stato</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Verficato</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{(data || []).map((product) => (
							<TableRow key={product.id}>
								{props.mode == 'list' && <TableCell>
									<Checkbox color={'primary'} onChange={(e, checked) => { onProductChecked(product, checked) }} />
								</TableCell>}
								<TableCell component="th" scope="row">
									{product.image ?
										<img style={{ ...styles.photoDimensions }} src={product.image_thumb} />
										:
										<img style={{ ...styles.photoDimensions }} src={'/assets/image-placeholder.png'} />
									}
								</TableCell>
								<TableCell component="th" scope="row">
									{product.ean_code}
								</TableCell>
								<TableCell component="th" scope="row">
									{product.brand}
								</TableCell>
								<TableCell component="th" scope="row">
									{product.name}
								</TableCell>
								<TableCell component="th" scope="row">
									{product.quantity}
								</TableCell>
								<TableCell component="th" scope="row">
									{product.categories?.map(c => c.it).join(" • ")}
								</TableCell>
								<TableCell component="th" scope="row">
									{statusString(product.status)}
								</TableCell>
								<TableCell component="th" scope="row">
									{product.verified ? "Sì" : ""}
								</TableCell>
								<TableCell style={{ width: 330 }} component="th" scope="row">
									{props.mode == 'list' && <CButton style={{ marginRight: 10 }} label="Duplica" onClick={() => onDuplicateClick(product)} />}
									{props.mode == 'list' && <CButton style={{ marginRight: 10 }} label="Elimina" onClick={() => { onDeleteClick(product) }} />}
									{props.mode == 'pending' && <CButton style={{ marginRight: 10 }} label="Rifiuta" onClick={() => rejectProductPress(product)} />}
									{props.mode == 'pending' && <CButton style={{ marginRight: 10 }} label="Approva" onClick={() => { approveProductPress(product) }} />}
									<CButton label="Visualizza" onClick={() => { onOpenPress(product) }} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				style={{ minHeight: 50 }}
				component="div"
				rowsPerPageOptions={[20, 50, 100]}
				count={pagination.count}
				rowsPerPage={pagination.rows}
				page={pagination.page}
				onPageChange={onPageChange}
				onRowsPerPageChange={(e) => {
					const value = parseInt(e.target.value)
					pagination.setRows(value)
					fetchData(value, 0, searchText)
				}}
			/>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={deleteConfirmOpen}
				onClose={() => setDeleteConfirmOpen(false)}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 400 }}>
						<div style={{ ...modalTitle }}>Conferma eliminazione</div>
						<div>Sei sicuro di voler eliminare i prodotti selezionati? Tutte le informazioni degli utenti collegate a questi prodotti verranno eliminate.</div>
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Annulla" onClick={() => setDeleteConfirmOpen(false)} />
							<CButton label="Elimina" onClick={onDeleteConfirm} />
						</div>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default ProductsPage

const styles: ItemsMap<CSSProperties> = {
	photoDimensions: {
		maxWidth: 40,
		maxHeight: 40
	}
}