import { ID } from "../../shoppinga-common/Models/Generic"
import { Allergen, Product } from "../../shoppinga-common/Models/Product"
import { http, loadUrl } from "./index"

export type GetProductsResponse = {
	products: Product[]
	query: string
	rows: number
	count: number
}
export type GetProductsParams = {
	query?: string
	ean_code?: string
	offset?: number
	limit?: number
	generic_first?: boolean
	only_generic?: boolean
	no_generic?: boolean
	user_id?: ID,
	status?: (string | number)[]
	search_only_name?: boolean,
	frequent?: boolean
	favourites?: boolean
	latitude?: number
	longitude?: number
}
export const getProducts = (api_url: string, jwt: string, params: GetProductsParams) => {
	const url = loadUrl('products', {
		query: params.query,
		ean_code: params.ean_code,
		offset: params.offset,
		limit: params.limit,
		no_generic: params.no_generic,
		only_generic: params.only_generic,
		generic_first: !!params.generic_first,
		user_id: params.user_id,
		status: params.status,
		search_only_name: params.search_only_name,
		favourites: params.favourites,
		latitude: params.latitude,
		longitude: params.longitude
	})
	return http<GetProductsResponse>(api_url, jwt, url)
}

export type GetProductResponse = {
	product: Product
}
export type GetProductParams = {
	latitude?: number
	longitude?: number
}
export const getProduct = (api_url: string, jwt: string, product_id: ID, params?: GetProductParams) => {
	return http<GetProductResponse>(api_url, jwt, loadUrl(`products/${product_id}`, params))
}

export type EditProductResponse = {
	product: Product
}
export const editProduct = (api_url: string, jwt: string, product_id: ID, data: Partial<Product>) => {
	return http<EditProductResponse>(api_url, jwt, `products/${product_id}`, 'PUT', data)
}

export type CreateProductResponse = {
	product: Product
}
export const createProduct = (api_url: string, jwt: string, user_id: ID, data: Partial<Product>) => {
	return http<CreateProductResponse>(api_url, jwt, 'products', 'POST', {
		...data,
		creator_id: user_id
	})
}

export type DeleteProductResponse = any
export const deleteProduct = (api_url: string, jwt: string, product_id: ID) => {
	return http<DeleteProductResponse>(api_url, jwt, `products/${product_id}`, 'DELETE')
}

export type GetAllergensResponse = {
	allergens: Allergen[]
}
export const getAllergens = (api_url: string, jwt: string) => {
	const url = loadUrl('allergens')
	return http<GetAllergensResponse>(api_url, jwt, url)
}