import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { embedInspirationCode, genericError, ItemsMap, successMessage } from '@Global/util';
import { useHistory, useParams } from 'react-router-dom';
import { modalActionsContainer, modalContainer, modalTitle, rootView } from '@Global/styles';
import { webPath } from 'src/App';
import { getProducts } from '../Network/products';
import { displayProductName, numberToAddForUnit, Product } from '../shoppinga-common/Models/Product';
import { multipart } from '../Network';
import { ID } from '../shoppinga-common/Models/Generic';
import { loadUserId } from '../Global/local.storage';
import CButton from '@Components/CButton';
import Modal from '@material-ui/core/Modal';
import { useQuery } from '../Global/hooks/query.hook';
import { useToasts } from 'react-toast-notifications';
import { Inspiration, isInspirationPending } from '../shoppinga-common/Models/Inspiration';
import { createInspiration, deleteInspiration, getInspiration, updateInspiration } from '../Network/inspirations';
import PhotoPicker, { ImageFile } from '@Components/PhotoPicker';
import CTextField from '@Components/CTextField';
import { colors } from '../shoppinga-client-common/util';
import { DEFAULT_UNIT, Unit, unitOptions } from '../shoppinga-common/Models/List';
import AutoComplete from '@Components/AutoComplete';
import { GetProductsResponse } from '../shoppinga-client-common/Network/products';
import { MenuItem, Select } from '@material-ui/core';

type Props = {

}

type IngredientRow = {
	product: Product | null
	qta: number
	unit: Unit
}

type TextInputProps = {
	title: string
	multiline?: boolean
	minWidth?: number
	maxWidth?: number
	field: string
	setter: React.Dispatch<React.SetStateAction<string>>
}
const TextInput = ({ title, multiline, minWidth, maxWidth, field, setter }: TextInputProps) => {
	return (
		<div style={styles.inputContainer}>
			<div style={styles.textTitle}>{title}</div>
			<CTextField multiline={multiline} style={{ minWidth, maxWidth }} value={field} onChange={setter} />
		</div>
	)
}

const EditInspirationPage = (props: Props) => {

	const history = useHistory()
	const { addToast } = useToasts();

	const self = useMemo<{ id: ID | null }>(() => ({
		id: null
	}), [])

	//@ts-ignore
	const { id } = useParams()
	const isNew = id == 'new'
	self.id = id

	const queryParams = useQuery()
	const copyFrom = queryParams.get("copy_from")

	const [inspiration, setInspiration] = useState<Inspiration>()
	const [displayPhoto, setDisplayPhoto] = useState<string | null>(null)
	const [photoObj, setPhotoObj] = useState<ImageFile | null>(null)
	const [name, setName] = useState("")
	const [link, setLink] = useState("")
	const [people, setPeople] = useState(1)
	const [description, setDescription] = useState("")
	const [mainProducts, setMainProducts] = useState<IngredientRow[]>([])
	const [baseProducts, setBaseProducts] = useState<IngredientRow[]>([])

	const [saveLoading, setSaveLoading] = useState(false)
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
	const [htmlModalOpen, setHtmlModalOpen] = useState(false)

	const fetchData = useCallback(async () => {
		const response = await getInspiration(self.id!)
		if (response.success && response.data) {
			if (copyFrom) {
				response.data.inspiration.photo = null
			}
			const inspiration = response.data.inspiration
			setInspiration(inspiration)
			setName(inspiration.name)
			setLink(inspiration.link)
			setPeople(inspiration.people)
			setDescription(inspiration.description)
			setDisplayPhoto(inspiration.photo)
			setBaseProducts((inspiration.base_product_links || []).map(l => ({ product: l.product || null, qta: l.qta, unit: l.unit })))
			setMainProducts((inspiration.main_product_links || []).map(l => ({ product: l.product || null, qta: l.qta, unit: l.unit })))
		} else {
			genericError(addToast)
		}
	}, [id, self, copyFrom])

	useEffect(() => {
		if (isNew) {
			if (copyFrom) {
				self.id = copyFrom
				fetchData()
			}
		} else {
			fetchData()
		}
	}, [id, self])

	const onPaste = useCallback((e) => {
		var item = e.clipboardData.items[0];

		if (item && item.type.indexOf("image") === 0) {
			var blob = item.getAsFile();
			setPhotoObj(blob)
			setDisplayPhoto(URL.createObjectURL(blob))
		}
	}, [])

	const onDeletePress = useCallback(() => {
		setDeleteConfirmOpen(true)
	}, [self])

	const onDeleteConfirm = useCallback(async () => {
		const response = await deleteInspiration(self.id!)
		if (response.success) {
			successMessage("Ispirazione eliminata con successo", addToast)
			history.goBack()
		} else {
			genericError(addToast)
		}
	}, [self])

	const onInspirationSave = async (openNew: boolean) => {
		setSaveLoading(true)

		const payload: Partial<Inspiration> = {
			name,
			link,
			description,
			people,
			user_id: await loadUserId(),
			main_product_links: mainProducts.map(p => ({ product_id: p.product!.id, qta: p.qta, unit: p.unit })),
			base_product_links: baseProducts.map(p => ({ product_id: p.product!.id, qta: p.qta, unit: p.unit })),
		}

		if (isNew) {
			const response = await createInspiration(payload)
			if (!response.success) {
				genericError(addToast)
				setSaveLoading(false)
				return
			}

			self.id = response.data?.inspiration.id!

		} else {
			const response = await updateInspiration(id, {
				name,
				link,
				description,
				people,
				main_product_links: mainProducts.map(p => ({ product_id: p.product!.id, qta: p.qta, unit: p.unit })),
				base_product_links: baseProducts.map(p => ({ product_id: p.product!.id, qta: p.qta, unit: p.unit })),
			})
			if (!response.success) {
				genericError(addToast)
				setSaveLoading(false)
				return
			}
		}

		if (photoObj) {
			const pictureResponse = await multipart(`inspirations/${self.id}/photo`, 'PUT', 'photo', photoObj)
			if (!pictureResponse.success) {
				genericError(addToast)
				setSaveLoading(false)
				return
			}
		}

		if (isNew) {
			successMessage("Ispirazione creata con successo", addToast)
		} else {
			successMessage("Prodotto aggiornato con successo", addToast)
		}

		if (openNew) {
			history.replace(webPath(
				`/admin/inspirations/new`
			))
			window.location.reload()
		}

		setSaveLoading(false)
	}

	const onDuplicateClick = useCallback(() => {
		//window.open(config.web_app + webPath('/admin/products/new?copy_from=' + self.id), "_blank")
		history.push(webPath('/admin/inspirations/new?copy_from=' + self.id))
	}, [self])

	const fetchProducts = useCallback((query: string) => {
		return getProducts({ query, generic_first: true })
	}, [])

	const onPhotoPicked = useCallback((file: any) => {
		console.log(file)
		if (file) {
			setPhotoObj(file)
			setDisplayPhoto(URL.createObjectURL(file))
		} else {
			setPhotoObj(null)
			setDisplayPhoto(null)
		}
	}, [])

	const onAddMainIngredientPress = () => {
		setMainProducts(mainProducts.concat([{
			product: null,
			qta: 1,
			unit: DEFAULT_UNIT
		}]))
	}

	const onAddBaseIngredientPress = useCallback(() => {
		setBaseProducts(baseProducts.concat([{
			product: null,
			qta: 1,
			unit: DEFAULT_UNIT
		}]))
	}, [baseProducts])

	const approveInspirationPress = useCallback(async () => {
		if (!inspiration) return
		try {
			const response = await updateInspiration(inspiration.id, { approved: true, rejected: false })
			if (response.success) {
				successMessage("Operazione completata", addToast)
				fetchData()
			} else {
				genericError(addToast)
			}
		} catch (e) {
			genericError(addToast)
		}
	}, [inspiration])

	const rejectInspirationPress = useCallback(async () => {
		if (!inspiration) return
		try {
			const response = await updateInspiration(inspiration.id, { approved: false, rejected: true })
			if (response.success) {
				successMessage("Operazione completata", addToast)
				fetchData()
			} else {
				genericError(addToast)
			}
		} catch (e) {
			genericError(addToast)
		}
	}, [inspiration])

	const onGenerateHTMLPress = useCallback(() => {
		setHtmlModalOpen(true)
	}, [])

	return (
		<div style={{ ...rootView }} onPaste={onPaste}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<PhotoPicker displayPhoto={displayPhoto} onPhotoPicked={onPhotoPicked} />
				<div style={{ marginLeft: 20 }}>
					<TextInput title="Nome" minWidth={500} field={name} setter={setName} />
					<TextInput title="Link" minWidth={500} field={link} setter={setLink} />
					<TextInput title="N° persone (ingredienti)" minWidth={500} field={`${people}`} setter={(text: any) => setPeople(text)} />
				</div>
			</div>
			<TextInput multiline title="Descrizione" minWidth={760} field={description} setter={setDescription} />


			<div style={{ margin: 10 }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ fontWeight: 'bold', width: 200 }}>Ingredienti principali</div>
					<CButton style={{ marginLeft: 20 }} label="Aggiungi" onClick={onAddMainIngredientPress} />
				</div>
				<div style={{ marginTop: 10 }}>
					{
						mainProducts.map((row, index) => {
							return (
								<div key={"m_" + index} style={{ marginBottom: 10, display: 'flex' }}>
									<AutoComplete<Product, GetProductsResponse>
										placeholder="Cerca..."
										data_key="products"
										index={3}
										hide_value
										value={row.product}
										mapping={(item) => ({ value: item.id, label: displayProductName(item), image: item.image_thumb, has_image: true })}
										request={fetchProducts}
										onItemSelected={(selected) => {
											setMainProducts(mainProducts.map((_row, _index) => {
												if (index == _index) return { ..._row, product: selected }
												return _row
											}))
										}}
										style={{ minWidth: 500, maxWidth: 500 }}
									/>
									<Select
										style={{ marginLeft: 20, width: 60 }}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={row.unit}
										label="Unità"
										onChange={(e) => {
											setMainProducts(mainProducts.map((_row, _index) => {
												if (index == _index) return { ..._row, unit: e.target.value as Unit }
												return _row
											}))
										}}
									>
										{unitOptions.map(item => {
											return (
												<MenuItem value={item.value} key={item.value}>{item.value}</MenuItem>
											)
										})}
									</Select>
									<div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
										<CButton label='-' onClick={() => {
											setMainProducts(mainProducts.map((_row, _index) => {
												if (index == _index) return { ..._row, qta: Math.max(_row.qta - numberToAddForUnit(_row.unit), 1) }
												return _row
											}))
										}} />
										<div style={{ marginLeft: 10, marginRight: 10, fontWeight: 'bold', width: 30, textAlign: 'center' }}>{row.qta}</div>
										<CButton label='+' onClick={() => {
											setMainProducts(mainProducts.map((_row, _index) => {
												if (index == _index) return { ..._row, qta: _row.qta + numberToAddForUnit(_row.unit) }
												return _row
											}))
										}} />
									</div>
									<CButton label='Rimuovi' style={{ marginLeft: 20 }} onClick={() => {
										setMainProducts(mainProducts.filter((p, _index) => index != _index))
									}} />
								</div>
							)
						})
					}
				</div>
			</div>


			<div style={{ margin: 10 }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ fontWeight: 'bold', width: 200 }}>Ingredienti base</div>
					<CButton style={{ marginLeft: 20 }} label="Aggiungi" onClick={onAddBaseIngredientPress} />
				</div>
				<div style={{ marginTop: 10 }}>
					{
						baseProducts.map((row, index) => {
							return (
								<div key={"b_" + index} style={{ marginBottom: 10, display: 'flex' }}>
									<AutoComplete<Product, GetProductsResponse>
										placeholder="Cerca..."
										data_key="products"
										index={3}
										hide_value
										value={row.product}
										mapping={(item) => ({ value: item.id, label: displayProductName(item), image: item.image_thumb, has_image: true })}
										request={fetchProducts}
										onItemSelected={(selected) => {
											setBaseProducts(baseProducts.map((_row, _index) => {
												if (index == _index) return { ..._row, product: selected }
												return _row
											}))
										}}
										style={{ minWidth: 500, maxWidth: 500 }}
									/>
									<Select
										style={{ marginLeft: 20, width: 60 }}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={row.unit}
										label="Unità"
										onChange={(e) => {
											setBaseProducts(baseProducts.map((_row, _index) => {
												if (index == _index) return { ..._row, unit: e.target.value as Unit }
												return _row
											}))
										}}
									>
										{unitOptions.map(item => {
											return (
												<MenuItem value={item.value} key={item.value}>{item.value}</MenuItem>
											)
										})}
									</Select>
									<div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
										<CButton label='-' onClick={() => {
											setBaseProducts(baseProducts.map((_row, _index) => {
												if (index == _index) return { ..._row, qta: Math.max(_row.qta - numberToAddForUnit(_row.unit), 1) }
												return _row
											}))
										}} />
										<div style={{ marginLeft: 10, marginRight: 10, fontWeight: 'bold', width: 30, textAlign: 'center' }}>{row.qta}</div>
										<CButton label='+' onClick={() => {
											setBaseProducts(baseProducts.map((_row, _index) => {
												if (index == _index) return { ..._row, qta: _row.qta + numberToAddForUnit(_row.unit) }
												return _row
											}))
										}} />
									</div>
									<CButton label='Rimuovi' style={{ marginLeft: 20 }} onClick={() => {
										setBaseProducts(baseProducts.filter((p, _index) => index != _index))
									}} />
								</div>
							)
						})
					}
				</div>
			</div>

			<div style={{ margin: 10, marginTop: 20 }}>
				{isInspirationPending(inspiration) && <CButton label='Approva' onClick={approveInspirationPress} />}
				{isInspirationPending(inspiration) && <CButton style={{ marginLeft: 10 }} label='Rifiuta' onClick={rejectInspirationPress} />}
				<CButton style={{ marginLeft: isInspirationPending(inspiration) ? 10 : 0 }} label='Salva ispirazione' onClick={() => onInspirationSave(false)} />
				<CButton label='Salva e nuovo' style={{ marginLeft: 10 }} onClick={() => onInspirationSave(true)} />
				{!isInspirationPending(inspiration) && <CButton style={{ marginLeft: 10 }} label="Genera HTML" onClick={onGenerateHTMLPress} />}
				{!isNew && <CButton style={{ marginLeft: 10 }} label="Duplica" onClick={onDuplicateClick} />}
				{!isNew && <CButton style={{ marginLeft: 10 }} label="Elimina" onClick={onDeletePress} />}
			</div>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={deleteConfirmOpen}
				onClose={() => setDeleteConfirmOpen(false)}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 400 }}>
						<div style={{ ...modalTitle }}>Conferma eliminazione</div>
						<div>Sei sicuro di voler eliminare questa ispirazione? Tutte le informazioni degli utenti collegate a questa ispirazione verranno eliminate.</div>
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Annulla" onClick={() => setDeleteConfirmOpen(false)} />
							<CButton label="Elimina" onClick={onDeleteConfirm} />
						</div>
					</div>
				</div>
			</Modal>
			{inspiration && <Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={htmlModalOpen}
				onClose={() => setHtmlModalOpen(false)}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 400 }}>
						<div style={{ ...modalTitle }}>Embed ispirazione</div>
						<div>{embedInspirationCode(inspiration!)}</div>
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Chiudi" onClick={() => setHtmlModalOpen(false)} />
							<CButton label="Copia" onClick={() => {
								navigator.clipboard.writeText(embedInspirationCode(inspiration!))
								setHtmlModalOpen(false)
								successMessage("Codice copiato negli appunti", addToast)
							}} />
						</div>
					</div>
				</div>
			</Modal>}
		</div>
	)
}

const styles: ItemsMap<CSSProperties> = {
	inputContainer: {
		margin: 10
	},
	textTitle: {
		fontWeight: 'bold'
	},
	photoContainerDimensions: {
		width: 120,
		height: 120,
		backgroundColor: colors.white,
		borderRadius: 10
	},
	photoDimensions: {
		maxWidth: 100,
		maxHeight: 100
	}
}

export default EditInspirationPage