import { ID } from "./Generic"
import { Product } from "./Product"
import { User } from "./User"

export type List = {
	id: ID
	name: string
	created_at: string
	user_id: ID
	user?: User
	items: ShopItem[]
	reminders?: Product[]
	users?: User[]
}

export const listUsers = (list: List) => {
	return [list.user].concat(list.users || [])
}

export type ShopItem = {
	quantity: number
	unit: Unit
	id: ID
	notes: string
	product_id: ID
	product?: Product
	savedItem?: SavedShopItem
	created_at: string
	completed_date?: string | null
	order: number
	list_id?: ID
	list?: List
}

export type Unit = " " | "pz" | "conf" | "kg" | "lt" | "hg" | "gr" | "cl" | "ct" | "qb"

export type SavedShopItem = Product & {
	unit?: Unit
	qta?: number
	deleted?: boolean
}

export const DEFAULT_UNIT: Unit = " "

export const unitOptions: { value: Unit }[] = [{
	value: " "
},{
	value: "pz",
},{
	value: "conf",
}, {
	value: "kg",
}, {
	value: "lt",
}, {
	value: "hg",
}, {
	value: "gr",
}, {
	value: "cl",
}, {
	value: "ct",
}, {
	value: "qb",
}]

export const DEFAULT_LIST_NAME = "Lista generica"

export const listName = (list?: List) => {
	return list?.name || DEFAULT_LIST_NAME
}