import { useMemo, useState } from "react"

type PaginationOptions = {
	count: number
	rows: number
	page: number
}

export const usePagination = () => {
	const [count, setCount] = useState(0)
	const [rows, setRows] = useState(0)
	const [page, setPage] = useState(0)

	const set = useMemo(() => {
		return (paginationOptions: Partial<PaginationOptions>) => {
			if(paginationOptions.count) setCount(paginationOptions.count)
			if(paginationOptions.rows) setRows(paginationOptions.rows)
			if(paginationOptions.page) setRows(paginationOptions.page)
		}
	}, [])

	return {
		set,
		count, setCount,
		rows, setRows,
		page, setPage
	}
}