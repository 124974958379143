import { CSSProperties } from 'react';
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';

type Props = {
	icon?: any
	style?: CSSProperties
	type?: string
	value?: string
	placeholder?: string
	multiline?: boolean
	onChange: (text: string) => void
}

const CTextField = (props: Props) => {
	return <TextField multiline={props.multiline} value={props.value} {...props.icon ? {
		InputProps: {
			startAdornment: (
				<InputAdornment position="start">
					{props.icon}
				</InputAdornment>
			),
		}
	} : {}} type={props.type} placeholder={props.placeholder} onChange={(e) => { props.onChange(e.target.value) }} style={{
		...props.style
	}} />
}

export default CTextField