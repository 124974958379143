import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { errorMessage, genericError, ItemsMap, successMessage } from '@Global/util';
import { useHistory, useParams } from 'react-router-dom';
import { rootView } from '@Global/styles';
import { webPath } from 'src/App';
import CTextField from '@Components/CTextField';
import CButton from '@Components/CButton';
import { colors } from '../shoppinga-client-common/util';
import AutoComplete from '@Components/AutoComplete';
import { displayProductName, Product } from '../shoppinga-common/Models/Product';
import { GetProductsResponse } from '../shoppinga-client-common/Network/products';
import { editProduct, getProducts } from '../Network/products';
import ProductInput, { ProductInputRef } from '@Components/ProductInput';
import { Shop } from '../shoppinga-common/Models/Shop';
import { getOfferExcludedShopBranches, getShopBranches, getShops, GetShopsResponse } from '../Network/shops';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { createOffer, CreateOfferPayload, editOffer, getOffer, getOffers, updateOfferShopBranchesLinks } from '../Network/offers';
import { ID } from '../shoppinga-common/Models/Generic';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import 'moment/locale/it'
import { multipart } from '../Network';
import config from '../config';
import { useQuery } from '../Global/hooks/query.hook';
import { useToasts } from 'react-toast-notifications';
import LargeSetSelect from '@Components/LargeSetSelect';
import { Offer, ShopBranch } from '../shoppinga-common/Models/Offer';

type Props = {

}

const EditOfferPage = (props: Props) => {

	const history = useHistory()
	const { addToast } = useToasts();

	const self = useMemo<{
		id: ID | null
		isNew: boolean
	}>(() => ({
		id: null,
		isNew: false
	}), [])

	//@ts-ignore
	const { id } = useParams()

	const [selectedProduct, setSelectedProduct] = useState<Product>()
	const [selectedShop, setSelectedShop] = useState<Shop>()
	const [excludedShopBranches, setExcludedShopBranches] = useState<ShopBranch[]>([])
	const [price, setPrice] = useState("")
	const [kgPrice, setKgPrice] = useState("")
	const [ltPrice, setLtPrice] = useState("")
	const [discountedPrice, setDiscountedPrice] = useState("")
	const [kgDiscountedPrice, setKgDiscountedPrice] = useState("")
	const [ltDiscountedPrice, setLtDiscountedPrice] = useState("")
	const [validFrom, setValidFrom] = useState<Date | null>(null)
	const [expiration, setExpiration] = useState<Date | null>(null)
	const [onlyPartners, setOnlyPartners] = useState(false)
	const [maxQuantity, setMaxQuantity] = useState<string | null>(null)
	const [saveLoading, setSaveLoading] = useState(false)
	const [saveNewLoading, setSaveNewLoading] = useState(false)

	const productInputRef = useRef<ProductInputRef>(null)

	const queryParams = useQuery()

	useEffect(() => {
		self.id = id
		self.isNew = id == 'new'
		if (!self.isNew) {
			fetchOffer(id)
		}
	}, [id])

	useEffect(() => {
		const queryShop = queryParams.get("shop")
		const queryValidFrom = queryParams.get("valid_from")
		const queryExpiration = queryParams.get("expiration")

		if (queryShop) setSelectedShop(JSON.parse(queryShop))
		if (queryValidFrom) setValidFrom(new Date(queryValidFrom))
		if (queryExpiration) setExpiration(moment(queryExpiration.replace("|", " ")).toDate())
	}, [])

	const fetchOffer = useCallback(async (offer_id: ID) => {
		const shop_branches_response = await getOfferExcludedShopBranches(+self.id!)
		if(shop_branches_response.success) {
			setExcludedShopBranches(shop_branches_response.data?.shop_branches || [])
		}
		
		const response = await getOffer(self.id!)
		if (response.success) {
			const offer = response.data?.offer
			setSelectedProduct(offer?.product)
			setSelectedShop(offer?.shop)
			setPrice(offer?.price?.toString() || "")
			setKgPrice(offer?.kg_price?.toString() || "")
			setLtPrice(offer?.lt_price?.toString() || "")
			setDiscountedPrice(offer?.discounted_price?.toString() || "")
			setKgDiscountedPrice(offer?.discounted_kg_price?.toString() || "")
			setLtDiscountedPrice(offer?.discounted_lt_price?.toString() || "")
			setValidFrom(moment(offer?.valid_from).toDate())
			setExpiration(moment(offer?.expiration).toDate())
			setOnlyPartners(offer?.only_partners || false)
			setMaxQuantity(offer?.max_quantity?.toString() || "")
		} else {
			genericError(addToast)
		}
	}, [self])

	const fetchProducts = useCallback((query: string) => {
		return getProducts({ query, generic_first: true })
	}, [])

	const getPrice = useMemo(() => {
		return (product: Product, shop: Shop) => {
			for (let i = 0; i < (product.prices?.length || 0); i++) {
				const price = product.prices![i]
				if (price.product_id == product.id && price.shop_id == shop.id) {
					return price
				}
			}
		}
	}, [])

	const _setSeletedProduct = useCallback((product?: Product) => {
		setSelectedProduct(product)
		if (product && selectedShop && !price) {
			const _price = getPrice(product, selectedShop)
			if (_price) {
				setPrice(_price.price?.toString())
			} else {
				setPrice("")
			}
		}
	}, [price, selectedShop])

	const _setSelectedShop = useCallback((shop?: Shop) => {
		setSelectedShop(shop)
		if (selectedProduct && shop && !price) {
			const _price = getPrice(selectedProduct, shop)
			if (_price) {
				setPrice(_price.price?.toString())
			} else {
				setPrice("")
			}
		}
	}, [price, selectedProduct])

	const onOnlyPartnersChange = useCallback((e: any, checked: boolean) => {
		setOnlyPartners(checked)
	}, [])

	const shopBranchesRequest = useCallback(async (query: string, limit?: number, offset?: number) => {
		if(!selectedShop) return
		return getShopBranches(selectedShop.id, query, limit, offset)
	}, [selectedShop])

	const onSaveOfferClick = useCallback(async (openNew?: boolean) => {

		const productData = productInputRef.current?.getProductInfo()

		if (!selectedProduct && !productData?.name) {
			errorMessage("Devi scegliere o inserire un prodotto", addToast)
			return
		}
		if (!selectedShop) {
			errorMessage("Devi scegliere una catena", addToast)
			return
		}
		if ((!price && !kgPrice && !ltPrice) || (!discountedPrice && !kgDiscountedPrice && !ltDiscountedPrice)) {
			errorMessage("Completa le informazioni dei prezzi", addToast)
			return
		}
		if (!validFrom || !expiration) {
			errorMessage("Completa le informazioni di validità", addToast)
			return
		}
		
		if(openNew) {
			setSaveNewLoading(true)
		} else {
			setSaveLoading(true)
		}
		
		const payload: Partial<CreateOfferPayload> = {}
		if (selectedProduct) {
			const response = await editProduct(selectedProduct.id, productData || {})
			if (!response.success) {
				if (response.error?.startsWith("0")) {
					errorMessage("L'ean che hai inserito è già presente", addToast)
					return
				}
				genericError(addToast)
				return
			}
			payload.product_id = selectedProduct.id
		} else {
			payload.new_product = productData
		}

		const validFromFormatted = moment(validFrom).format("YYYY-MM-DD") || undefined
		const expirationFormatted = (moment(expiration).format("YYYY-MM-DD") + " 23:59") || undefined

		payload.offer = {
			shop_id: selectedShop?.id,
			price: price ? parseFloat(price) : undefined,
			kg_price: kgPrice ? parseFloat(kgPrice) : undefined,
			lt_price: ltPrice ? parseFloat(ltPrice) : undefined,
			discounted_price: discountedPrice ? parseFloat(discountedPrice) : undefined,
			discounted_kg_price: kgDiscountedPrice ? parseFloat(kgDiscountedPrice) : undefined,
			discounted_lt_price: ltDiscountedPrice ? parseFloat(ltDiscountedPrice) : undefined,
			valid_from: validFromFormatted,
			expiration: expirationFormatted,
			only_partners: onlyPartners,
			max_quantity: maxQuantity ? maxQuantity : undefined
		}

		let product_id: ID
		let offer: Offer

		if (self.isNew) {
			const response = await createOffer(payload)
			if (!response.success) {
				if (response.error?.startsWith("0")) {
					errorMessage("L'ean che hai inserito è già presente", addToast)
					return
				}
				genericError(addToast)
				return
			}
			offer = response.data!.offer
			product_id = response.data?.offer.product_id!
		} else {
			const response = await editOffer(self.id!, payload)
			if (!response.success) {
				if (response.error?.startsWith("0")) {
					errorMessage("L'ean che hai inserito è già presente", addToast)
					return
				}
				genericError(addToast)
				return
			}
			offer = response.data!.offer
			product_id = response.data?.offer.product_id!
		}

		if (product_id && productData?.photo) {
			const pictureResponse = await multipart(`products/${product_id}/picture`, 'PUT', 'picture', productData.photo)
			if (!pictureResponse.success) {
				genericError(addToast)
				return
			}
		}

		if(offer) {
			await updateOfferShopBranchesLinks(offer.id, excludedShopBranches.map(b => b.id))
		}

		if (self.isNew) {
			successMessage("Offerta creata con successo", addToast)
		} else {
			successMessage("Offerta aggiornata con successo", addToast)
		}

		if (openNew) {
			const passingShop: any = selectedShop
			delete passingShop.color
			history.replace(webPath(
				`/admin/offers/new?shop=${JSON.stringify(passingShop)}&valid_from=${validFromFormatted}&expiration=${expirationFormatted?.replace(" ", "|")}`
			))
			window.location.reload()
		} else if (self.isNew) {
			history.goBack()
		}

		if(openNew) {
			setSaveNewLoading(false)
		} else {
			setSaveLoading(false)
		}

	}, [
		self,
		productInputRef,
		selectedProduct,
		selectedShop,
		excludedShopBranches,
		price,
		discountedPrice,
		kgPrice,
		ltPrice,
		kgDiscountedPrice,
		ltDiscountedPrice,
		validFrom,
		expiration,
		onlyPartners,
		maxQuantity
	])

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale="it">
			<div style={{ ...rootView, overflow: 'scroll' }}>
				<div style={{ ...styles.inputContainer }}>
					<div style={{ ...styles.textTitle, marginBottom: 10 }}>Prodotto</div>
					<AutoComplete<Product, GetProductsResponse>
						placeholder="Cerca..."
						data_key="products"
						index={3}
						value={selectedProduct}
						mapping={(item) => ({ value: item.id, label: displayProductName(item), image: item.image_thumb, has_image: true })}
						request={fetchProducts}
						onItemSelected={_setSeletedProduct}
						onClear={() => { _setSeletedProduct(undefined) }}
						style={{ minWidth: 500, maxWidth: 500 }}
					/>
				</div>

				<ProductInput
					product={selectedProduct}
					ref={productInputRef}
					hideActions
				/>

				<div style={{ ...styles.inputContainer, marginTop: 20, display: 'flex', flexDirection: 'column' }}>
					<div>
						<div style={{ ...styles.textTitle, marginBottom: 10 }}>Catena</div>
						<AutoComplete<Shop, GetShopsResponse>
							placeholder="Cerca..."
							data_key="shops"
							index={3}
							value={selectedShop}
							mapping={(item) => ({ value: item.id, label: item.name })}
							request={getShops}
							onItemSelected={_setSelectedShop}
							onClear={() => { _setSelectedShop(undefined) }}
							style={{ minWidth: 500, maxWidth: 500 }}
						/>
					</div>
					{selectedShop && <LargeSetSelect<ShopBranch, any>
						modalTitle='Seleziona punti vendita da escludere'
						widget={(onOpen) => <div style={{ marginTop: 10 }}>
							<CButton label='Seleziona punti vendita da escludere' onClick={onOpen}/>
							{excludedShopBranches.length > 0 && <div style={{ marginTop: 10 }}>
								<div style={{ fontWeight: 'bold' }}>Punti vendita esclusi:</div>
								{excludedShopBranches.map((item, index) => (
									<div key={index}>
										<div style={{ display: 'flex' }}><div style={{ fontWeight: 'bold', marginRight: 3 }}>{item.name}</div>{`(${item.address}, ${item.city})`}</div>
									</div>
								))}
							</div>}
						</div>}
						data_key='shop_branches'
						mapping={(branch) => ({ label: `${branch.name} (${branch.address}, ${branch.city})`, value: branch.id })}
						multi
						values={excludedShopBranches}
						request={shopBranchesRequest}
						onMultipleItemsSelected={setExcludedShopBranches}
					/>}
				</div>

				<div style={{ display: 'flex', marginTop: 10 }}>
					<div>
						<div style={{ ...styles.inputContainer, marginLeft: 10 }}>
							<div style={{ ...styles.textTitle, marginBottom: 10 }}>Prezzo intero</div>
							<CTextField
								value={price}
								onChange={(text: string) => { setPrice(text) }}
							/>
						</div>
						<div style={{ ...styles.inputContainer, marginLeft: 10 }}>
							<div style={{ ...styles.textTitle, marginBottom: 10 }}>Prezzo intero al Kg</div>
							<CTextField
								value={kgPrice}
								onChange={(text: string) => { setKgPrice(text) }}
							/>
						</div>
						<div style={{ ...styles.inputContainer, marginLeft: 10 }}>
							<div style={{ ...styles.textTitle, marginBottom: 10 }}>Prezzo intero al lt</div>
							<CTextField
								value={ltPrice}
								onChange={(text: string) => { setLtPrice(text) }}
							/>
						</div>
					</div>
					<div>
						<div style={{ ...styles.inputContainer, marginLeft: 20, display: 'flex', alignItems: 'center' }}>
							<div>
								<div style={{ ...styles.textTitle, marginBottom: 10 }}>Prezzo scontato</div>
								<CTextField
									value={discountedPrice}
									onChange={(text: string) => { setDiscountedPrice(text) }}
								/>
							</div>
							{price && discountedPrice && <div style={{ marginLeft: 20, fontWeight: 'bold', fontSize: 20 }}>
								Sconto: {Math.round((1 - (parseFloat(discountedPrice) / parseFloat(price))) * 100)} %
							</div>}
						</div>
						<div style={{ ...styles.inputContainer, marginLeft: 20, display: 'flex', alignItems: 'center' }}>
							<div>
								<div style={{ ...styles.textTitle, marginBottom: 10 }}>Prezzo scontato al Kg</div>
								<CTextField
									value={kgDiscountedPrice}
									onChange={(text: string) => { setKgDiscountedPrice(text) }}
								/>
							</div>
							{kgPrice && kgDiscountedPrice && <div style={{ marginLeft: 20, fontWeight: 'bold', fontSize: 20 }}>
								Sconto: {Math.round((1 - (parseFloat(kgDiscountedPrice) / parseFloat(kgPrice))) * 100)} %
							</div>}
						</div>
						<div style={{ ...styles.inputContainer, marginLeft: 20, display: 'flex', alignItems: 'center' }}>
							<div>
								<div style={{ ...styles.textTitle, marginBottom: 10 }}>Prezzo scontato al lt</div>
								<CTextField
									value={ltDiscountedPrice}
									onChange={(text: string) => { setLtDiscountedPrice(text) }}
								/>
							</div>
							{ltPrice && ltDiscountedPrice && <div style={{ marginLeft: 20, fontWeight: 'bold', fontSize: 20 }}>
								Sconto: {Math.round((1 - (parseFloat(ltDiscountedPrice) / parseFloat(ltPrice))) * 100)} %
							</div>}
						</div>
					</div>

				</div>

				<div style={{ display: 'flex', marginTop: 20 }}>
					<div style={{ ...styles.inputContainer, marginLeft: 10 }}>
						<div style={{ ...styles.textTitle, marginBottom: 10 }}>Valida da</div>
						<DatePicker value={validFrom} format="DD/MM/YYYY" onChange={(date) => {
							setValidFrom(date?.toDate() || null)
						}} />
					</div>
					<div style={{ ...styles.inputContainer, marginLeft: 20 }}>
						<div style={{ ...styles.textTitle, marginBottom: 10 }}>Valida fino a</div>
						<DatePicker value={expiration} format="DD/MM/YYYY" onChange={(date) => {
							setExpiration(date?.toDate() || null)
						}} />
					</div>
				</div>

				<div style={{ ...styles.inputContainer, display: 'flex', alignItems: 'center' }}>
					<FormGroup>
						<FormControlLabel
							onChange={onOnlyPartnersChange}
							control={<Checkbox checked={onlyPartners} color={'primary'} />}
							label="Solo soci"
						/>
					</FormGroup>
					<div style={{ ...styles.inputContainer, marginLeft: 20 }}>
						<div style={{ ...styles.textTitle, marginBottom: 10 }}>Max pezzi</div>
						<CTextField
							value={maxQuantity ? maxQuantity.toString() : undefined}
							onChange={(text: string) => { setMaxQuantity(text) }}
						/>
					</div>
				</div>

				<div style={{ ...styles.inputContainer, marginTop: 20 }}>
					<CButton loading={saveLoading} label="Salva" onClick={() => { onSaveOfferClick(false) }} />
					<CButton loading={saveNewLoading} style={{ marginLeft: 10 }} label="Salva e nuovo" onClick={() => { onSaveOfferClick(true) }} />
				</div>
			</div>
		</MuiPickersUtilsProvider>
	)
}

export default EditOfferPage

const styles: ItemsMap<CSSProperties> = {
	inputContainer: {
		margin: 10
	},
	textTitle: {
		fontWeight: 'bold'
	},
	photoContainerDimensions: {
		width: 120,
		height: 120,
		backgroundColor: colors.white,
		borderRadius: 10
	},
	photoDimensions: {
		maxWidth: 100,
		maxHeight: 100
	}
}