import React, { useCallback, useEffect, useState } from 'react';
import { login, saveSessionInfo } from '@Network/auth';
import { formatPrice, genericError, GENERIC_ERROR_MESSAGE, successMessage } from '@Global/util';
import PCButton from '@Components/CButton';
import PCTextField from '@Components/CTextField';
import { useHistory } from 'react-router-dom';
import { modalActionsContainer, modalContainer, modalTitle, rootView } from '@Global/styles';
import { webPath } from 'src/App';
import CTextField from '@Components/CTextField';
import CButton from '@Components/CButton';
import { Search } from '@material-ui/icons';
import config from '../config';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Modal, TableSortLabel, Box, SortDirection } from '@material-ui/core';
import { displayProductName, statusString } from '../shoppinga-common/Models/Product';
import { usePagination } from '../Global/hooks/pagination.hook';
import { discountedOfferPrice, Offer, offerPrice } from '../shoppinga-common/Models/Offer';
import { deleteOffer, getOffers } from '../Network/offers';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import { Shop } from '../shoppinga-common/Models/Shop';
import { getShops } from '../Network/shops';
import MultiSelect from '@Components/MultiSelect';

type Props = {

}

const OffersPage = (props: Props) => {

	const history = useHistory()
	const { addToast } = useToasts();

	const pagination = usePagination()
	const [shops, setShops] = useState<Shop[]>([])
	const [selectedShops, setSelectedShops] = useState<Shop[]>([])
	const [data, setData] = useState<Offer[]>()
	const [deletingOffer, setDeletingOffer] = useState<Offer>()
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

	const [fromDateOrderMode, setFromDateOrderMode] = useState<SortDirection>('desc')

	const fetchOffers = useCallback(async (limit?: number, offset?: number, shops?: Shop[], sort_from_date_mode: 'asc' | 'desc' = 'desc') => {
		const response = await getOffers({
			limit,
			offset,
			shops: shops?.map((s) => s.id),
			sort_from_date_mode
		})
		if (response.success) {
			setData(response.data?.offers)
			pagination.set({
				rows: response.data?.rows,
				count: response.data?.count
			})
		} else {
			genericError(addToast);
		}
	}, [])

	const fetchShops = useCallback(async () => {
		const response = await getShops("")
		if (response.success) {
			setShops(response.data?.shops || [])
		} else {
			genericError(addToast)
		}
	}, [])

	useEffect(() => {
		fetchOffers(20, 0)
		fetchShops()
	}, [])

	const onNewOfferClick = useCallback(() => {
		//window.open(config.web_app + webPath('/admin/offers/new'), "_blank")
		history.push(webPath('/admin/offers/new'))
	}, [])

	const onOpenPress = useCallback((offer: Offer) => {
		//window.open(config.web_app + webPath('/admin/offers/' + offer.id), "_blank")
		history.push(webPath('/admin/offers/' + offer.id))
	}, [])

	const onDeletePress = useCallback((offer: Offer) => {
		setDeletingOffer(offer)
		setDeleteConfirmOpen(true)
	}, [])

	const onDeleteConfirm = useCallback(async () => {
		const response = await deleteOffer(deletingOffer!.id)
		if (response.success) {
			successMessage("Offerta eliminata con successo", addToast)
			setDeleteConfirmOpen(false)
			fetchOffers(pagination.rows, pagination.rows * pagination.page, selectedShops)
		} else {
			genericError(addToast)
		}
	}, [deletingOffer, pagination, selectedShops])

	const onPageChange = useCallback((e, newPage: number) => {
		pagination.setPage(newPage)
		fetchOffers(pagination.rows, pagination.rows * newPage, selectedShops)
	}, [pagination, selectedShops])

	return (
		<div style={{ ...rootView, padding: 20 }}>
			<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
				{/* <CTextField style={{ width: 400 }} icon={<Search />} placeholder={"Cerca tramite nome o categoria"} onChange={onSearch} /> */}
				<div style={{ minWidth: 400 }}>
					<MultiSelect<Shop>
						isMulti={false}
						items={shops}
						mapping={s => ({ value: s.id, label: s.name })}
						onChange={(selected) => {
							setSelectedShops(selected)
							fetchOffers(pagination.rows, 0, selected)
						}}
						value={selectedShops}
					/>
				</div>
				<CButton style={{ marginLeft: 20, marginRight: 25 }} label="Aggiungi offerta" onClick={onNewOfferClick} />
			</div>
			<TableContainer style={{ marginTop: 20 }} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 'bold' }}>Catena</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Prodotto</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>EAN</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Prezzo intero</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Prezzo scontato</TableCell>
							<TableCell sortDirection={fromDateOrderMode} style={{ fontWeight: 'bold' }}>
								<TableSortLabel
									active={true}
									direction={fromDateOrderMode || undefined}
									onClick={() => {
										const newOrderMode = fromDateOrderMode == 'asc' ? 'desc' : 'asc'
										setFromDateOrderMode(newOrderMode)
										fetchOffers(pagination.rows, 0, selectedShops, newOrderMode)
									}}
								>
									Da
								</TableSortLabel>
							</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>A</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{(data || []).map((offer) => (
							<TableRow key={offer.id}>
								<TableCell component="th" scope="row">
									{offer.shop?.name}
								</TableCell>
								<TableCell component="th" scope="row">
									{displayProductName(offer.product!)}
								</TableCell>
								<TableCell component="th" scope="row">
									{offer.product?.ean_code}
								</TableCell>
								<TableCell component="th" scope="row">
									{offerPrice(offer) && formatPrice(offerPrice(offer))}
								</TableCell>
								<TableCell component="th" scope="row">
									{discountedOfferPrice(offer) && formatPrice(discountedOfferPrice(offer))}
								</TableCell>
								<TableCell component="th" scope="row">
									{moment(offer.valid_from).format("DD/MM/YYYY")}
								</TableCell>
								<TableCell component="th" scope="row">
									{moment(offer.expiration).format("DD/MM/YYYY")}
								</TableCell>
								<TableCell style={{ width: 220 }} component="th" scope="row">
									<CButton label="Visualizza" onClick={() => { onOpenPress(offer) }} />
									<CButton style={{ marginLeft: 10 }} label="Elimina" onClick={() => { onDeletePress(offer) }} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				style={{ minHeight: 50 }}
				component="div"
				rowsPerPageOptions={[20, 50, 100]}
				count={pagination.count}
				rowsPerPage={pagination.rows}
				page={pagination.page}
				onPageChange={onPageChange}
				onRowsPerPageChange={(e) => {
					const value = parseInt(e.target.value)
					pagination.setRows(value)
					fetchOffers(value, 0, selectedShops)
				}}
			/>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={deleteConfirmOpen}
				onClose={() => setDeleteConfirmOpen(false)}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 400 }}>
						<div style={{ ...modalTitle }}>Conferma eliminazione</div>
						<div>Sei sicuro di voler eliminare questa offerta? Questa operazione è irreversibile.</div>
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Annulla" onClick={() => setDeleteConfirmOpen(false)} />
							<CButton label="Elimina" onClick={onDeleteConfirm} />
						</div>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default OffersPage