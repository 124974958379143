export const colors = {
	white: '#fff',
	black: '#000',
	primary: "#3A4CA0",
	red: "#f42",
	blue: "#3A4CA0",
	orange: "#fcba03",
	green: "#C0D837",
	sidebarSelected: '#5368cf',
	fucsia: "#ea1160",
	brown: '#CD9946',
	darkRed: '#C94359',
	lightBlack: '#342F29',
	darkGrey: '#63675A',
	lightBlue: '#A0DDF8',
	mediumBlue: "#4A9CD5",
	indigo: '#1B9CAA',
	transparent: '#0000',
	darkTransparent: "#0008",
	lightGrey: '#eee',
	midGrey: "#ddd",
	yellow: '#F5ED20',
	greyOffers: '#f7f7f7',
	grey: "#C0BDBD"
}

export const isDefined = (data: any) => {
	return !!data
}

export const formatPrice = (value: number) => {
	return value.toFixed(2).toString().split(".").join(",") + " €"
}