import { CSSProperties, useRef, useCallback } from 'react';
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import { ItemsMap } from '../Global/util';
import { colors } from '../shoppinga-client-common/util';
import CButton from './CButton';

export type ImageFile = { name: string, type: string }

type Props = {
	displayPhoto: string | null
	onPhotoPicked: (file: ImageFile) => void
}

const PhotoPicker = (props: Props) => {

	const inputFile = useRef(null)

	const onChooseImageClicked = useCallback(() => {
		//@ts-ignore
		inputFile.current?.click()
	}, [inputFile])

	const onImageChange = useCallback((event) => {
		if (event.target.files && event.target.files[0]) {
			props.onPhotoPicked(event.target.files[0])
		}
	}, [])


	return (
		<div style={{ ...styles.inputContainer, display: 'flex' }}>
			<div>
				<div style={styles.textTitle}>Foto</div>
				<div style={{ ...styles.photoContainerDimensions, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
					{
						props.displayPhoto ?
							<img style={{ ...styles.photoDimensions }} src={props.displayPhoto} />
							:
							<img style={{ ...styles.photoDimensions }} src={'/assets/image-placeholder.png'} />
					}
				</div>
			</div>
			<input ref={inputFile} style={{ display: 'none' }} type="file" onChange={onImageChange} className="filetype" />
			<CButton style={{ alignSelf: 'flex-end', marginLeft: 20 }} label="Carica" onClick={onChooseImageClicked} />
		</div>
	)
}

const styles: ItemsMap<CSSProperties> = {
	inputContainer: {
		margin: 10
	},
	textTitle: {
		fontWeight: 'bold'
	},
	photoContainerDimensions: {
		width: 120,
		height: 120,
		backgroundColor: colors.white,
		borderRadius: 10
	},
	photoDimensions: {
		maxWidth: 100,
		maxHeight: 100
	}
}

export default PhotoPicker