/* export default {
	env: 'dev',
	web_app: "http://localhost:3000",
	api_url: 'http://192.168.8.133:8888/api/'
} */

export default {
	env: 'prod',
	web_app: "https://app.shoppinga.it",
	api_url: 'https://app.shoppinga.it/api/'
}