import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { genericError, ItemsMap, successMessage } from '@Global/util';
import CButton from '@Components/CButton';
import CTextField from '@Components/CTextField';
import { useHistory } from 'react-router-dom';
import { modalActionsContainer, modalContainer, modalTitle, rootView } from '@Global/styles';
import { webPath } from 'src/App';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Search } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import { usePagination } from '../Global/hooks/pagination.hook';
import Modal from '@material-ui/core/Modal';
import { Checkbox } from '@material-ui/core';
import { ID } from '../shoppinga-common/Models/Generic';
import { useToasts } from 'react-toast-notifications';
import { Inspiration } from '../shoppinga-common/Models/Inspiration';
import { deleteInspiration, deleteInspirationsBulk, getInspirations, updateInspiration } from '../Network/inspirations';

type Props = {
	mode: 'list' | 'pending'
}

const InspirationsPage = (props: Props) => {

	const { addToast } = useToasts();

	const history = useHistory()
	const self: {
		query: string
	} = useMemo(() => ({
		query: "",
	}), [])

	const [data, setData] = useState<Inspiration[]>([])
	const [searchText, setSearchText] = useState("")
	const pagination = usePagination()
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
	const [deletingInspiration, setDeletingInspiration] = useState<Inspiration | null>(null)
	const [selectedInspirations, setSelectedInspirations] = useState<ID[]>([])

	const fetchData = useCallback(async (rows: number, page: number, query: string) => {
		const response = await getInspirations({
			published: props.mode == "list" ? true : undefined,
			pending: props.mode == "pending" ? true : undefined,
			offset: rows * page,
			limit: rows || 20,
			query,
		})
		if (response.success) {
			setData(response.data?.inspirations || [])
			pagination.set({
				count: response.data?.count || 0,
				rows: response.data?.rows || 0
			})
		} else {
			genericError(addToast)
		}
	}, [pagination, props.mode])

	useEffect(() => {
		fetchData(20, 0, "")
		setSearchText("")
	}, [props.mode])

	const onSearch = useCallback((text: string) => {
		self.query = text
		setTimeout(() => {
			if (text == self.query) {
				setSearchText(text)
				pagination.setPage(0)
				fetchData(0, 0, text)
			}
		}, 500)
	}, [self, props.mode])

	const onPageChange = useCallback((e, newPage: number) => {
		pagination.setPage(newPage)
		fetchData(pagination.rows, newPage, searchText)
	}, [pagination, searchText, props.mode])

	const onDeleteClick = useCallback((inspiration: Inspiration) => {
		setDeletingInspiration(inspiration)
		setDeleteConfirmOpen(true)
	}, [props.mode])

	const onDeleteBulkClick = useCallback(() => {
		setDeleteConfirmOpen(true)
	}, [])

	const onOpenPress = useCallback((inspiration: Inspiration) => {
		//window.open(config.web_app + webPath('/admin/products/' + (props.mode == 'pending' ? "pending/" : "") + product.id), "_blank")
		history.push(webPath('/admin/inspirations/' + (props.mode == 'pending' ? "pending/" : "") + inspiration.id))
	}, [props.mode])

	const onNewInspiration = useCallback(() => {
		//window.open(config.web_app + webPath('/admin/products/new'), "_blank")
		history.push(webPath('/admin/inspirations/new'))
	}, [props.mode])

	const onDeleteConfirm = useCallback(async () => {
		let response
		if(selectedInspirations.length > 0) {
			response = await deleteInspirationsBulk(selectedInspirations)
		} else {
			response = await deleteInspiration(deletingInspiration!.id)
		}

		if (response.success) {
			successMessage("Ispirazione/i eliminata/e con successo", addToast)
			setDeleteConfirmOpen(false)
			fetchData(pagination.rows, pagination.page, searchText)
		} else {
			genericError(addToast)
		}
	}, [deletingInspiration, selectedInspirations, pagination, searchText])

	const onDuplicateClick = useCallback((inspiration: Inspiration) => {
		//window.open(config.web_app + webPath('/admin/products/new?copy_from=' + product.id), "_blank")
		history.push(webPath('/admin/inspirations/new?copy_from=' + inspiration.id))
	}, [pagination, searchText])

	const approveInspirationPress = useCallback(async (inspiration: Inspiration) => {
		try {
			const response = await updateInspiration(inspiration.id, { approved: true, rejected: false })
			if(response.success) {
				successMessage("Operazione completata", addToast)
				fetchData(pagination.rows, pagination.page, searchText)
			} else {
				genericError(addToast)
			}
		} catch (e) {
			genericError(addToast)
		}
	}, [pagination, searchText])

	const rejectInspirationPress = useCallback(async (inspiration: Inspiration) => {
		try {
			const response = await updateInspiration(inspiration.id, { approved: false, rejected: true })
			if(response.success) {
				successMessage("Operazione completata", addToast)
				fetchData(pagination.rows, pagination.page, searchText)
			} else {
				genericError(addToast)
			}
		} catch (e) {
			genericError(addToast)
		}
	}, [pagination, searchText])

	const onInspirationChecked = useCallback((inspiration: Inspiration, checked: boolean) => {
		if (checked) {
			if (!selectedInspirations.includes(inspiration.id)) {
				setSelectedInspirations(selectedInspirations.concat([inspiration.id]))
			}
		} else {
			setSelectedInspirations(selectedInspirations.filter((i) => {
				return i != inspiration.id
			}))
		}
	}, [self, selectedInspirations])

	return (
		<div style={{ ...rootView, padding: 20 }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<CTextField style={{ width: 400 }} icon={<Search />} placeholder={"Cerca tramite nome o ingredienti"} onChange={onSearch} />
				<div>
					{props.mode == 'list' && selectedInspirations.length > 0 && <CButton style={{ marginRight: 10 }} label="Elimina selezionate" onClick={onDeleteBulkClick} />}
					{props.mode == 'list' && <CButton style={{ marginRight: 50 }} label="Crea ispirazione" onClick={onNewInspiration} />}
				</div>
			</div>
			<TableContainer style={{ marginTop: 20 }} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							{props.mode == 'list' && <TableCell />}
							<TableCell style={{ fontWeight: 'bold' }}>Foto</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{(data || []).map((inspiration) => (
							<TableRow key={inspiration.id}>
								{props.mode == 'list' && <TableCell width={40}>
									<Checkbox color={'primary'} onChange={(e, checked) => { onInspirationChecked(inspiration, checked) }} />
								</TableCell>}
								<TableCell width={60} component="th" scope="row">
									{inspiration.photo ?
										<img style={{ ...styles.photoDimensions }} src={inspiration.photo} />
										:
										<img style={{ ...styles.photoDimensions }} src={'/assets/image-placeholder.png'} />
									}
								</TableCell>
								<TableCell component="th" scope="row">
									{inspiration.name}
								</TableCell>
								<TableCell style={{ width: 330 }} component="th" scope="row">
									{props.mode == 'list' && <CButton style={{ marginRight: 10 }} label="Duplica" onClick={() => onDuplicateClick(inspiration)} />}
									{props.mode == 'list' && <CButton style={{ marginRight: 10 }} label="Elimina" onClick={() => { onDeleteClick(inspiration) }} />}
									{props.mode == 'pending' && <CButton style={{ marginRight: 10 }} label="Rifiuta" onClick={() => rejectInspirationPress(inspiration)} />}
									{props.mode == 'pending' && <CButton style={{ marginRight: 10 }} label="Approva" onClick={() => { approveInspirationPress(inspiration) }} />}
									<CButton label="Visualizza" onClick={() => { onOpenPress(inspiration) }} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				style={{ minHeight: 50 }}
				component="div"
				rowsPerPageOptions={[20, 50, 100]}
				count={pagination.count}
				rowsPerPage={pagination.rows}
				page={pagination.page}
				onPageChange={onPageChange}
				onRowsPerPageChange={(e) => {
					const value = parseInt(e.target.value)
					pagination.setRows(value)
					fetchData(value, 0, searchText)
				}}
			/>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={deleteConfirmOpen}
				onClose={() => setDeleteConfirmOpen(false)}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 400 }}>
						<div style={{ ...modalTitle }}>Conferma eliminazione</div>
						<div>Sei sicuro di voler eliminare le ispirazioni selezionate? Tutte le informazioni degli utenti collegate a queste ispirazioni verranno eliminate.</div>
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Annulla" onClick={() => setDeleteConfirmOpen(false)} />
							<CButton label="Elimina" onClick={onDeleteConfirm} />
						</div>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default InspirationsPage

const styles: ItemsMap<CSSProperties> = {
	photoDimensions: {
		maxWidth: 40,
		maxHeight: 40
	}
}