import { components, MultiValue, SingleValue } from 'react-select';
import ReactSelect from 'react-select'
import { useCallback, useMemo } from 'react';
import { colors } from '../shoppinga-client-common/util';

export type DisplayItem = { value: number | string, label: string, has_image?: boolean, image?: string | undefined }

type Props<T> = {
	width?: number
	inputValue?: string
	value?: T[]
	isMulti: boolean
	items: T[]
	mapping: (item: T) => DisplayItem
	onChange: (selected: T[]) => void
	onSearch?: (text: string) => void
}

const styles = {
	photoDimensions: {
		maxWidth: 40,
		maxHeight: 40
	}
}

const Option = (props: any) => {
	return (
		<div>
			<components.Option {...props}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<input
						type="checkbox"
						checked={props.isSelected}
						onChange={() => null}
					/>{" "}
					{props.data.has_image && <div style={{
						width: 50,
						height: 50,
						backgroundColor: colors.white,
						borderRadius: 10,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginLeft: 10,
						marginRight: 10
					}}>
						{props.data.image ?
							<img style={{ ...styles.photoDimensions }} src={props.data.image} />
							:
							<img style={{ ...styles.photoDimensions }} src={'/assets/image-placeholder.png'} />}
					</div>}
					<label>{props.label}</label>
				</div>
			</components.Option>
		</div>
	);
};

const MultiSelect = <T extends unknown>(props: Props<T>) => {

	const getSelectedValues = useCallback((selected: MultiValue<DisplayItem> | SingleValue<DisplayItem> | null) => {
		const result: T[] = []
		if (selected) {
			if ('length' in selected) {

			} else {
				selected = [selected]
			}
		} else {
			return []
		}

		selected.forEach((selectedValue) => {
			props.items.forEach((item) => {
				if (props.mapping(item).value == selectedValue.value) {
					result.push(item)
				}
			})
		})

		return result
	}, [props.items])

	return (
		<ReactSelect
			styles={{
				valueContainer: (_) => ({
					..._,
					width: props.width || 300
				}),
				menuList: (_) => ({
					..._,
					maxHeight: 850
				})
			}}
			onInputChange={(text) => {
				props.onSearch?.(text)
			}}
			isClearable
			options={props.items.map(i => props.mapping(i))}
			isMulti={props.isMulti}
			closeMenuOnSelect={false}
			hideSelectedOptions={false}
			components={{
				Option
			}}
			onChange={(selected) => {
				props.onChange(getSelectedValues(selected))
			}}
			value={props.value?.map(item => props.mapping(item))}
		/>
	)
}

export default MultiSelect