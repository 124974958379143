import { ID } from "./Generic";
import { Product } from "./Product";
import { Shop } from "./Shop";

export type Offer = {
	id: ID
	price: number
	kg_price: number
	lt_price: number
	discounted_price: number
	discounted_kg_price: number
	discounted_lt_price: number
	valid_from: string
	expiration: string
	only_partners: boolean
	max_quantity: string
	created_at: string
	product_id: ID,
	product?: Product
	shop_id: ID
	shop?: Shop
	offer_shop_branch_link?: OfferShopBranchLink[]
}

export type OfferShopBranchLink = {
	shop_branch_id: number
	shop_branch?: ShopBranch
	offer_id: number
}

export type ShopBranch = {
	id: number
	name: string
	address: string
	city: string
	latitude: number
	longitude: number
	shop_id: number
	shop?: Shop
}

export const offerPrice = (offer: Offer) => {
	return offer.price || offer.kg_price || offer.lt_price
}

export const discountedOfferPrice = (offer: Offer) => {
	return offer.discounted_price || offer.discounted_kg_price || offer.discounted_lt_price
}

export const offerDiscount = (offer: Offer) => {
	let price: number, discounted_price: number
	if(offer.price && offer.discounted_price) {
		price = offer.price
		discounted_price = offer.discounted_price
	} else if(offer.kg_price && offer.discounted_kg_price) {
		price = offer.kg_price
		discounted_price = offer.discounted_kg_price
	} else {
		price = offer.lt_price
		discounted_price = offer.discounted_lt_price
	}
	return -1 * Math.round((1 - (discounted_price / price)) * 100)
}