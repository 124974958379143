import React from 'react';
import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom';
import Login from './Pages/LoginPage';
import Home from './Pages/Home';
import AdminRouter from './Admin.Router';
import config from './config';
import { ToastProvider } from 'react-toast-notifications';

export const webPath = (path: string) => {
	return "/web" + path
}

function App() {

	if (window.location.host.includes("localhost") && config.api_url.includes("https")) {
		alert("Sei in locale o in production?")
	}

	return (
		<ToastProvider>
			<Router>
				<div style={{ display: 'flex', flex: 1 }}>
					<Switch>

						<Route
							exact
							path={"/"}
							component={Home}
						/>
						<Route
							exact
							path={"/web"}
							component={Home}
						/>

						<Route
							path={webPath('/login')}
							component={Login}
						/>

						<AdminRouter />

					</Switch>
				</div>
			</Router>
		</ToastProvider>
	);
}

export default App;
