import { CSSProperties } from "react";
import { colors } from "../shoppinga-client-common/util";

export const rootView: CSSProperties = {
	display: 'flex',
	flexDirection: 'column',
	overflow: 'scroll',
	backgroundColor: colors.lightGrey,
	flex: 1
}

export const modalContainer: CSSProperties = {
	backgroundColor: colors.white,
	padding: "20px 30px 20px 30px"
}

export const modalTitle: CSSProperties = {
	fontSize: 20,
	fontWeight: 'bold',
	marginBottom: 20
}

export const modalActionsContainer: CSSProperties = {
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: 20
}