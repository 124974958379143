import { HTTPMethod, RequestResponse, http as _http } from "@ClientCommon/Network"
import { webPath } from "src/App"
import config from "src/config"
import { loadJWT } from "@Global/local.storage"
import axios from "axios"

export const http = async <T extends unknown>(url: string, method: HTTPMethod = 'GET', body?: any): Promise<RequestResponse<T>> => {
	const response = await _http<T>(config.api_url, await loadJWT(), url, method, body)
	if (response.auth_expired) {
		window.location.href = webPath('/login')
		return {
			success: false,
			data: null
		}
	} else {
		return response
	}
}

export const multipart = async <T extends unknown>(url: string, method: HTTPMethod, file_key: string, file: any): Promise<RequestResponse<T>> => {

	const formData = new FormData();
	formData.append(file_key || 'picture', file, file.name);

	try {
		const response = await axios.put<RequestResponse<T>>(config.api_url + url, formData, {
			headers: {
				'Auth': 'Bearer ' + loadJWT(),
			}
		})

		if (response.status == 403) {
			return {
				success: false,
				auth_expired: true,
				data: null
			}
		} else if (response.status == 423) {
			return {
				success: false,
				user_locked: true,
				data: null
			}
		} else {
			const data = response.data
			data.success = response.status >= 200 && response.status < 300
			return data
		}
	} catch (e: any) {
		console.log(e)
		return {
			success: false,
			data: null,
			error: e
		}
	}
}