import { ID } from "./Generic"
import { Unit } from "./List"
import { Product } from "./Product"
import { User } from "./User"

export type Inspiration = {
	id: ID
	name: string
	description: string
	photo: string | null
	link: string
	people: number
	publish: boolean
	tags?: InspirationTag[]
	user_id: ID
	user?: User
	main_product_links?: ProductInspirationLink[]
	base_product_links?: ProductInspirationLink[]
	favourites?: number
	is_favourite?: boolean
	approved?: boolean
	rejected?: boolean
}

export const getInspirationLink = (inspiration: Inspiration) => {
	if(!inspiration.link) return undefined
	if(!inspiration.link.startsWith("http://") && !inspiration.link.startsWith("https://")) return `https://${inspiration.link}`
	return inspiration.link
}

export type InspirationTag = {
	id: ID
	label: string
}

export type ProductInspirationLink = {
	inspiration_id?: ID
	inspiration?: Inspiration
	product_id: ID
	product?: Product
	link_type?: "main" | "base"
	qta: number,
	unit: Unit
}

export const isInspirationPending = (inspiration?: Inspiration) => {
	if(!inspiration) return false
	return !inspiration.approved && !inspiration.rejected
}