import { useHistory } from 'react-router-dom';
import { webPath } from 'src/App';

type Props = {
	
}

const Home = (props: Props) => {

	const history = useHistory()
	history.push(webPath('/login'))

	return (
		<>

		</>
	)
}

export default Home