import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { login, saveSessionInfo } from '@Network/auth';
import { errorMessage, genericError, GENERIC_ERROR_MESSAGE, successMessage } from '@Global/util';
import PCButton from '@Components/CButton';
import PCTextField from '@Components/CTextField';
import { useHistory, useParams } from 'react-router-dom';
import { modalActionsContainer, modalContainer, modalTitle, rootView } from '@Global/styles';
import { webPath } from 'src/App';
import { createProduct, deleteProduct, editProduct, getProduct } from '../Network/products';
import { Product } from '../shoppinga-common/Models/Product';
import ProductInput from '@Components/ProductInput';
import { multipart } from '../Network';
import { ID } from '../shoppinga-common/Models/Generic';
import { loadUserId } from '../Global/local.storage';
import CButton from '@Components/CButton';
import Modal from '@material-ui/core/Modal';
import { useQuery } from '../Global/hooks/query.hook';
import config from '../config';
import { useToasts } from 'react-toast-notifications';

type Props = {

}

const EditProductPage = (props: Props) => {

	const history = useHistory()
	const { addToast } = useToasts();

	const self = useMemo<{ id: ID | null }>(() => ({
		id: null
	}), [])

	//@ts-ignore
	const { id } = useParams()
	const isNew = id == 'new'
	self.id = id

	const queryParams = useQuery()
	const copyFrom = queryParams.get("copy_from")

	const [product, setProduct] = useState<Product>()
	const [saveLoading, setSaveLoading] = useState(false)
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

	const fetchData = useCallback(async () => {
		const response = await getProduct(self.id!)
		if (response.success) {
			if(copyFrom) {
				delete response.data?.product.image
				delete response.data?.product.image_thumb
			}
			setProduct(response.data?.product)
		} else {
			genericError(addToast)
		}
	}, [id, self, copyFrom])

	useEffect(() => {
		if (isNew) {
			if(copyFrom) {
				self.id = copyFrom
				fetchData()
			}
		} else {
			fetchData()
		}
	}, [id, self])

	const onDeletePress = useCallback(() => {
		setDeleteConfirmOpen(true)
	}, [self])

	const onDeleteConfirm = useCallback(async () => {
		const response = await deleteProduct(self.id!)
		if(response.success) {
			successMessage("Prodotto eliminato con successo", addToast)
			history.goBack()
		} else {
			genericError(addToast)
		}
	}, [self])

	const onProductSave = useCallback(async (data: Partial<Product> & { openNew?: boolean }, photo?: string) => {
		setSaveLoading(true)
		if (isNew) {
			const response = await createProduct(loadUserId(), data)
			if (!response.success) {
				if(response.error?.startsWith("0")) {
					errorMessage("L'ean che hai inserito è già presente", addToast)
					setSaveLoading(false)
					return
				}
				genericError(addToast)
				setSaveLoading(false)
				return
			}

			self.id = response.data?.product.id!

		} else {
			const response = await editProduct(id, data)
			if (!response.success) {
				if(response.error?.startsWith("0")) {
					errorMessage("L'ean che hai inserito è già presente", addToast)
					setSaveLoading(false)
					return
				}
				
				genericError(addToast)
				setSaveLoading(false)
				return
			}
		}

		if (photo) {
			const pictureResponse = await multipart(`products/${self.id}/picture`, 'PUT', 'picture', photo)
			if (!pictureResponse.success) {
				genericError(addToast)
				setSaveLoading(false)
				return
			}
		}

		if (isNew) {
			successMessage("Prodotto creato con successo", addToast)
		} else {
			successMessage("Prodotto aggiornato con successo", addToast)
		}

		if(data.openNew) {
			history.replace(webPath(
				`/admin/products/new`
			))
			window.location.reload()
		}

		setSaveLoading(false)
	}, [isNew, self])

	const onDuplicateClick = useCallback(() => {
		//window.open(config.web_app + webPath('/admin/products/new?copy_from=' + self.id), "_blank")
		history.push(webPath('/admin/products/new?copy_from=' + self.id))
	}, [self])

	const onStatusChange = useCallback(() => {
		window.location.reload();
	}, [])

	return (
		<div style={{ ...rootView }}>
			<ProductInput
				product={product}
				onStatusChange={onStatusChange}
				onSave={onProductSave}
				saveLoading={saveLoading}
			/>
			{!isNew && <div style={{ margin: 10 }}>
				<CButton style={{ marginRight: 10 }} label="Duplica" onClick={onDuplicateClick}/>
				<CButton label="Elimina" onClick={onDeletePress} />
			</div>}
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={deleteConfirmOpen}
				onClose={() => setDeleteConfirmOpen(false)}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 400 }}>
						<div style={{ ...modalTitle }}>Conferma eliminazione</div>
						<div>Sei sicuro di voler eliminare questo prodotto? Tutte le informazioni degli utenti collegate a questo prodotto verranno eliminate.</div>
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Annulla" onClick={() => setDeleteConfirmOpen(false)}/>
							<CButton label="Elimina" onClick={onDeleteConfirm}/>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default EditProductPage