import { ID } from "./Generic";
import { Unit } from "./List";
import { Offer } from "./Offer";
import { Shop } from "./Shop";
import { User } from "./User";

export enum ProductStatus {
	Pending = 0,
	Approved = 1,
	Rejected = 2
}

export const statusString = (status: ProductStatus) => {
	if (status == 0) return "In attesa"
	if (status == 1) return "Approvato"
	if (status == 2) return "Rifiutato"
	return "Sconosciuto"
}

export type Product = {
	id: ID
	/** 0: pending, 1: approved, 2: rejected */
	status: ProductStatus
	verified?: boolean
	open_ff_id?: string
	ean_code?: string
	name: string
	description?: string
	preparation?: string
	conservation?: string
	lang?: string
	categories_string?: string
	brand?: string
	image?: string
	image_thumb?: string
	packaging?: string
	quantity?: string //Campo ottenuto da open_ff
	unit: Unit //Unità che serve per proporre di default un'unità per chi aggiunge il prodotto in lista
	right_qta_num_items: number
	right_qta_quantity: number
	right_qta_unit: Unit
	production_country?: string
	nutri_score?: number
	nutri_score_grade?: string
	creator_id: ID
	creator?: User
	categories?: ProductCategory[]
	places?: ProductPlace[]
	it_categories: string
	it_places: string
	prices?: ProductPrice[]
	allergens?: Allergen[]
	ingredients?: Ingredient[]
	offers?: Offer[]
	is_favourite?: boolean
	//** Nutriments */
	kcal?: number
	proteins?: number
	carbohydrates?: number
	fat?: number
	saturated_fat?: number
	sugars?: number
	omega3_fat?: number
	fiber?: number
	salt?: number
	sodium?: number
	alcohol?: number
	vitamin_a?: number
	vitamin_b1?: number
	vitamin_b2?: number
	vitamin_b6?: number
	vitamin_b9?: number
	vitamin_b12?: number
	vitamin_c?: number
	vitamin_d?: number
	vitamin_e?: number
	vitamin_k?: number
	vitamin_pp?: number
	biotin?: number
	potassium?: number
	iron?: number
	phosphorus?: number
	calcium?: number
	magnesium?: number
	chloride?: number
	zinc?: number
	selenium?: number
	iodine?: number
}

export type ProductCategory = {
	id: ID
	label_id: string
	it: string
	product_id?: ID
}

export type ProductPlace = {
	id: ID
	label_id: string
	it: string
	color: string
	product_id?: ID
}

export type ProductPrice = {
	id: ID
	price: number
	kg_price: number
	lt_price: number
	product_id: ID
	product?: Product
	shop_id: ID
	shop: Shop
}

export type Allergen = {
	id: ID
	label_id: string
	it: string
	photo: string
	product_id?: ID
}

export type Ingredient = {
	id: ID
	label_id: string
	it: string
	product_id?: ID
}

export const displayProductName = (product: Product) => {
	if (product.brand) return product.brand + " - " + product.name
	return product.name
}

export const productFormat = (product: Product) => {
	if (product.right_qta_quantity && product.right_qta_unit) {
		if (!product.right_qta_num_items || product.right_qta_num_items == 1) return product.right_qta_quantity + " " + product.right_qta_unit
		else return product.right_qta_num_items + "x " + product.right_qta_quantity + " " + product.right_qta_unit
	} return product.quantity
}

export const categoryLabelIdFromLabel = (label: string) => {
	return "c-" + label.split(", ").join("-").split(" ").join("-").toLowerCase()
}

export const ingredientLabelIdFromLabel = (label: string) => {
	return "i-" + label.split(", ").join("-").split(" ").join("-").toLowerCase()
}

export const hasNutriments = (product?: Product) => {
	return product?.kcal != undefined ||
		product?.proteins != undefined ||
		product?.carbohydrates != undefined ||
		product?.sugars != undefined ||
		product?.fat != undefined ||
		product?.saturated_fat != undefined ||
		product?.fiber != undefined ||
		product?.salt != undefined
}

export const hasVitamins = (product?: Product) => {
	return product?.vitamin_a != undefined ||
		product?.vitamin_b1 != undefined ||
		product?.vitamin_b2 != undefined ||
		product?.vitamin_b6 != undefined ||
		product?.vitamin_b9 != undefined ||
		product?.vitamin_b12 != undefined ||
		product?.vitamin_c != undefined ||
		product?.vitamin_d != undefined ||
		product?.vitamin_e != undefined ||
		product?.vitamin_k != undefined ||
		product?.vitamin_pp != undefined
}

export const hasMinerals = (product?: Product) => {
	return product?.biotin != undefined ||
		product?.potassium != undefined ||
		product?.iron != undefined ||
		product?.phosphorus != undefined ||
		product?.calcium != undefined ||
		product?.magnesium != undefined ||
		product?.chloride != undefined ||
		product?.zinc != undefined ||
		product?.selenium != undefined ||
		product?.iodine ||
		product?.sodium
}

export const numberToAddForUnit = (unit: Unit | undefined) => {
	switch (unit) {
		case "kg": return 0.5
		case "lt": return 0.5
		case "hg": return 0.5
		case "gr": return 25
		default: return 1
	}
}