import { UserRole, User } from "@Common/Models/User";
import { initializeApp } from "firebase/app";
import { loadJWT, saveJWT, saveUserId, saveUserRole } from "../Global/local.storage";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import config from "../config";
import { http } from ".";

const firebaseConfig = {
	apiKey: "AIzaSyAEF0ESmDbNgFFjizn0RzXBhPQXX8cVQbA",
  authDomain: "shoppinga-fcadf.firebaseapp.com",
  projectId: "shoppinga-fcadf",
  storageBucket: "shoppinga-fcadf.appspot.com",
  messagingSenderId: "916543109135",
  appId: "1:916543109135:web:85d925b7e693ee33f64b7a",
  measurementId: "G-XSSM6B47KP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export const login = async (email: string, password: string) => {
	const response = await signInWithEmailAndPassword(auth, email, password);
	console.log(response)
	const auth_token = await response.user.getIdToken()
	const user = await userFromToken(email, auth_token)
	return user
}

type UserFromTokenResponse = {
	user: User
	jwt: string
}
export const userFromToken = async (email: string, auth_token: string) => {
	return http<UserFromTokenResponse>('auth', 'POST', {
		email,
		auth_token
	})
}

export const saveSessionInfo = (user: User, jwt: string) => {
	saveJWT(jwt || "")
	saveUserId(user.id + "")
	saveUserRole(user.role)
}

export const logout = async () => {
	saveJWT(null)
	saveUserId(null)
	return signOut(auth)
}