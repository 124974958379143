import { modalTitle } from '../../Global/styles'
import { RequestResponse } from '@App/src/shoppinga-client-common/Network'
import CButton from '@Components/CButton'
import CModal from '@Components/CModal'
import CTextField from '@Components/CTextField'
import { DisplayItem } from '@Components/MultiSelect'
import Checkbox from '@material-ui/core/Checkbox'
import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { ItemsMap } from '@App/src/Global/util'

const DEFAULT_PAGE_SIZE = 20

type Props<T, P> = {
	widget: (onOpen: () => void) => ReactNode
	style?: CSSProperties
	modalTitle?: string
	searchPlaceholder?: string
	index?: number //Serve per lo zindex
	value?: T | null
	values?: T[]
	data_key: string
	hide_value?: boolean
	multi?: boolean
	pageSize?: number
	mapping: (item: T) => DisplayItem
	request: (query: string, limit?: number, offset?: number) => Promise<RequestResponse<P> | undefined>
	onItemSelected?: (item: T) => void
	onMultipleItemsSelected?: (items: T[]) => void
	onClear?: () => void
}
const LargeSetSelect = <T, P>(props: Props<T, P>) => {

	const [modalOpen, setModalOpen] = useState(false)
	const [finishedData, setFinishedData] = useState(false)

	const self: {
		search: string
		selectedValue: T | null
	} = useMemo(() => {
		return {
			search: "",
			selectedValue: null
		}
	}, [])

	const [selectedAll, setSelectedAll] = useState(false)
	const [items, setItems] = useState<T[]>([])
	const [selectedItems, setSelectedItems] = useState<T[]>(props.values || [])

	useEffect(() => {
		fetchData(self.search, true)
	}, [])

	const fetchData = useCallback(async (text: string, add: boolean) => {
		const response = await props.request(text, props.pageSize || DEFAULT_PAGE_SIZE, items.length)
		if (response?.success) {
			const newItems = (response.data as any)?.[props.data_key] as T[]
			if (newItems.length < (props.pageSize || DEFAULT_PAGE_SIZE)) {
				setFinishedData(true)
			}
			if (add) {
				setItems(items.concat(newItems))
			} else {
				setItems(newItems)
				setFinishedData(false)
			}
		}
	}, [props.request, self, items])

	const onSearch = useCallback((text: string) => {
		self.search = text

		setTimeout(() => {
			if (text == self.search) {
				fetchData(text, false)
			}
		}, 500)
	}, [self])

	const onCheckChange = (item: T, checked: boolean) => {
		if (checked) {
			setSelectedItems(selectedItems.concat([item]))
		} else {
			setSelectedItems(selectedItems.filter(_item => props.mapping(_item).value != props.mapping(item).value))
		}
	}

	return (
		<div>
			{props.widget(() => setModalOpen(true))}
			<CModal open={modalOpen} title={props.modalTitle} actions={[
				{ label: "Chiudi", onPress: () => setModalOpen(false) },
				{
					label: "Conferma", onPress: () => {
						setModalOpen(false)
						props.onMultipleItemsSelected?.(selectedItems)
					}
				}
			]}>
				<div style={{ minHeight: 500 }}>
					<CTextField style={{ width: "100%", marginTop: 10 }} placeholder={props.searchPlaceholder || "Cerca"} onChange={onSearch} />
					{props.multi && <div style={{ marginTop: 20 }}>
						<Checkbox
							checked={selectedAll}
							color={'primary'}
							onChange={(e, checked) => {
								setSelectedAll(checked)
								setSelectedItems(checked ? items : [])
							}}
						/>
						Seleziona tutto
					</div>}

					<div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
						{items.map((item, index) => (
							<div style={{
								display: 'flex',
								alignItems: 'center'
							}} {...(!props.multi ? { onClick: () => props.onItemSelected?.(item) } : {})} key={index}>
								{props.multi && <Checkbox
									checked={!!selectedItems?.find(_item => props.mapping(_item).value == props.mapping(item).value)}
									color={'primary'}
									onChange={(e, checked) => onCheckChange(item, checked)}
								/>}
								{props.mapping(item).label}
							</div>
						))}
						{items.length > 0 && !finishedData && <div style={{ alignSelf: 'center', margin: "20px 0px 10px" }}>
							<CButton label='Mostra altri risultati' onClick={() => fetchData(self.search, true)} />
						</div>}
					</div>
				</div>
			</CModal>
		</div>
	)
}



export default LargeSetSelect
