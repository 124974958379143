import { Inspiration } from "../shoppinga-common/Models/Inspiration"

export const GENERIC_ERROR_TITLE = "Errore"
export const GENERIC_ERROR_MESSAGE = "Si è verificato un errore, riprova."

export const genericError = (toastFunction: any) => {
	errorMessage(GENERIC_ERROR_MESSAGE, toastFunction)
}

export const errorMessage = (text: string, toastFunction: any) => {
	toastFunction(text, { appearance: 'error', autoDismiss: true })
}

export const successMessage = (text: string, toastFunction: any) => {
	toastFunction(text, { appearance: 'success', autoDismiss: true })
}

export const formatPrice = (price: number) => {
	return price.toFixed(2) + "€"
}

export type ItemsMap<T> = {
	[index: string]: T | undefined
}

export const embedInspirationCode = (inspiration: Inspiration) => {
	const html = `<div style="background-color: #3a4ca0; padding: 10px 20px; border-radius: 10px; display: inline-block;">
<link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet'>
<a style="font-family: 'Lato'; color: #fff; font-weight: bold; text-decoration: none;" href="https://app.shoppinga.it/share/inspiration/${inspiration.id}">Salva ingredienti</a>
</div>`
	return html
}