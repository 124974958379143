import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import { useCallback, useEffect, useState } from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { webPath } from "./App"
import { colors } from "@ClientCommon/util"
import { logout } from "@Network/auth"
import ProductsPage from "./Pages/ProductsPage"
import OffersPage from "./Pages/OffersPage"
import EditProductPage from "./Pages/EditProductPage"
import EditOfferPage from "./Pages/EditOfferPage"
import CategoriesPage from "./Pages/CategoriesPage"
import IngredientsPage from "./Pages/IngredientsPage"
import InspirationsPage from "./Pages/InspirationsPage"
import EditInspirationPage from "./Pages/EditInspirationPage"

type Props = {

}

type Page = 'products' | 'pending-products' | 'categories' | 'ingredients' | 'offers' | 'inspirations' | 'pending-inspirations'
const pagesMap: { type: Page, start: string }[] = [
	{ type: 'pending-products', start: "/admin/products/pending" },
	{ type: 'products', start: "/admin/products" },
	{ type: 'categories', start: "/admin/categories" },
	{ type: 'ingredients', start: "/admin/ingredients" },
	{ type: 'offers', start: "/admin/offers" },
	{ type: 'pending-inspirations', start: "/admin/inspirations/pending" },
	{ type: 'inspirations', start: "/admin/inspirations" }
]

const AdminRouter = (props: Props) => {
	const history = useHistory()
	const [pageSelected, setPageSelected] = useState<Page>('products')

	useEffect(() => {
		for(let i=0; i<pagesMap.length; i++) {
			if(window.location.href.includes(pagesMap[i].start)) {
				setPageSelected(pagesMap[i].type)
				return
			}
		}
	})

	const onLogoutPress = useCallback(async () => {
		await logout()
		history.push(webPath('/login'))
	}, [window.location.href])

	const handleClickListItem = (page: Page) => {
		setPageSelected(page)

    if(page == 'products') {
			history.push(webPath("/admin/products"))
		}

		if(page == 'pending-products') {
			history.push(webPath("/admin/products/pending"))
		}

		if(page == 'categories') {
			history.push(webPath("/admin/categories"))
		}

		if(page == 'ingredients') {
			history.push(webPath("/admin/ingredients"))
		}

		if(page == 'offers') {
			history.push(webPath("/admin/offers"))
		}

		if(page == 'inspirations') {
			history.push(webPath("/admin/inspirations"))
		}

		if(page == 'pending-inspirations') {
			history.push(webPath("/admin/inspirations/pending"))
		}
  };

	return (
		<div style={{ display: 'flex', flex: 1 }}>
			<div style={{ width: 200, backgroundColor: colors.primary, color: colors.white }}>
				<MenuList>
					<MenuItem 
						style={{ backgroundColor: pageSelected == 'products' ? colors.sidebarSelected : colors.primary }} 
						onClick={() => { handleClickListItem('products') }}>
							Prodotti
					</MenuItem>
					<MenuItem 
						style={{ backgroundColor: pageSelected == 'pending-products' ? colors.sidebarSelected : colors.primary }} 
						onClick={() => { handleClickListItem('pending-products') }}>
							Prodotti in attesa
					</MenuItem>
					<MenuItem 
						style={{ backgroundColor: pageSelected == 'categories' ? colors.sidebarSelected : colors.primary }} 
						onClick={() => { handleClickListItem('categories') }}>
							Categorie
					</MenuItem>
					<MenuItem 
						style={{ backgroundColor: pageSelected == 'ingredients' ? colors.sidebarSelected : colors.primary }} 
						onClick={() => { handleClickListItem('ingredients') }}>
							Ingredienti
					</MenuItem>
					<MenuItem 
						style={{ backgroundColor: pageSelected == 'offers' ? colors.sidebarSelected : colors.primary }} 
						onClick={() => { handleClickListItem('offers') }}>
							Offerte
					</MenuItem>
					<MenuItem 
						style={{ backgroundColor: pageSelected == 'inspirations' ? colors.sidebarSelected : colors.primary }} 
						onClick={() => { handleClickListItem('inspirations') }}>
							Ispirazioni
					</MenuItem>
					<MenuItem 
						style={{ backgroundColor: pageSelected == 'pending-inspirations' ? colors.sidebarSelected : colors.primary }} 
						onClick={() => { handleClickListItem('pending-inspirations') }}>
							Ispirazioni in attesa
					</MenuItem>
					<MenuItem style={{ marginTop: 30 }} onClick={onLogoutPress}>Logout</MenuItem>
				</MenuList>
			</div>
			<div style={{ display: 'flex', flex: 1 }}>
				<Switch>
					
					<Route exact path={webPath("/admin/products")}>
						<ProductsPage mode='list'/>
					</Route>
					
					<Route exact path={webPath("/admin/products/pending")}>
						<ProductsPage mode='pending'/>
					</Route>

					<Route path={webPath("/admin/products/pending/:id")}>
						<EditProductPage />
					</Route>
					
					<Route exact path={webPath("/admin/offers")}>
						<OffersPage />
					</Route>
					
					<Route path={webPath("/admin/offers/:id")}>
						<EditOfferPage />
					</Route>
					
					<Route path={webPath("/admin/products/:id")}>
						<EditProductPage />
					</Route>

					<Route path={webPath("/admin/categories")}>
						<CategoriesPage />
					</Route>

					<Route path={webPath("/admin/ingredients")}>
						<IngredientsPage />
					</Route>

					<Route exact path={webPath("/admin/inspirations")}>
						<InspirationsPage mode="list" />
					</Route>

					<Route exact path={webPath("/admin/inspirations/pending")}>
						<InspirationsPage mode="pending" />
					</Route>

					<Route exact path={webPath("/admin/inspirations/pending/:id")}>
						<EditInspirationPage />
					</Route>

					<Route exact path={webPath("/admin/inspirations/:id")}>
						<EditInspirationPage />
					</Route>
				</Switch>
			</div>
		</div>
	)
}

export default AdminRouter