import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { genericError, ItemsMap, successMessage } from '@Global/util';
import { useHistory } from 'react-router-dom';
import { modalActionsContainer, modalContainer, modalTitle, rootView } from '@Global/styles';
import CTextField from '@Components/CTextField';
import CButton from '@Components/CButton';
import { Ingredient } from '../shoppinga-common/Models/Product';
import { createIngredient, deleteIngredient, getIngredients, updateIngredient } from '../Network/products';
import { TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Modal, TablePagination } from '@material-ui/core';
import 'moment/locale/it'
import { useToasts } from 'react-toast-notifications';
import { usePagination } from '../Global/hooks/pagination.hook';

type Props = {

}

const DEFAULT_PAGE = 50

const IngredientsPage = (props: Props) => {

	const history = useHistory()
	const { addToast } = useToasts();

	const [ingredients, setIngredients] = useState<Ingredient[]>([])
	const [displayIngredients, setDisplayIngredients] = useState<Ingredient[]>([])
	const pagination = usePagination()
	
	const [createIngredientModalOpen, setCreateIngredientModalOpen] = useState(false)
	const [newIngredientLabel, setNewIngredientLabel] = useState("")
	const [createIngredientLoading, setCreateIngredientLoading] = useState(false)
	const [editingIngredient, setEditingIngredient] = useState<Ingredient>()

	const [deletingIngredient, setDeletingIngredient] = useState<Ingredient>()

	useEffect(() => {
		fetchIngredients()
	}, [])

	const fetchIngredients = useCallback(async () => {
		const response = await getIngredients("")
		if (response.success) {
			setIngredients(response.data?.ingredients || [])
			pagination.set({
				count: response.data?.ingredients.length || 0,
				rows: DEFAULT_PAGE
			})
			showItems(response.data?.ingredients || [], DEFAULT_PAGE, 0)
		} else {
			genericError(addToast)
		}
	}, [])

	const showItems = useCallback((data: Ingredient[], rows: number, page: number) => {
		const start_index = rows * page
		const result: Ingredient[] = []
		for(let i = start_index; i<Math.min(start_index + rows, data.length); i++) {
			result.push(data[i])
		}
		setDisplayIngredients(result)
	}, [])

	const openCreateIngredientModal = useCallback(() => {
		setCreateIngredientModalOpen(true)
		setNewIngredientLabel("")
	}, [])

	const openUpdateIngredientModal = useCallback((ingredient: Ingredient) => {
		setEditingIngredient(ingredient)
		setNewIngredientLabel(ingredient.it)
		setCreateIngredientModalOpen(true)
	}, [])

	const closeIngredientModal = useCallback(() => {
		setEditingIngredient(undefined)
		setNewIngredientLabel("")
		setCreateIngredientModalOpen(false)
	}, [])

	const closeDeleteModal = useCallback(() => {
		setDeletingIngredient(undefined)
	}, [])

	const onCreateIngredientLabelChange = useCallback((text: string) => {
		setNewIngredientLabel(text)
	}, [])

	const onSaveIngredientConfirm = useCallback(async () => {
		setCreateIngredientLoading(true)

		let response
		if(editingIngredient) {
			response = await updateIngredient(editingIngredient.id, {
				label: newIngredientLabel
			})
		} else {
			response = await createIngredient(newIngredientLabel)
		}
		if(response.success) {
			if(editingIngredient) {
				successMessage("Ingrediente modificato con successo", addToast)
			} else {
				successMessage("Ingrediente creato con successo", addToast)
			}
			fetchIngredients()
			closeIngredientModal()
		} else {
			genericError(addToast)
		}

		setCreateIngredientLoading(false)
	}, [newIngredientLabel, editingIngredient])

	const onDeleteIngredientConfirm = useCallback(async () => {
		setCreateIngredientLoading(true)

		const response = await deleteIngredient(deletingIngredient!.id)
		if(response.success) {
			successMessage("Ingrediente eliminato con successo", addToast)
			fetchIngredients()
			closeDeleteModal()
		} else {
			genericError(addToast)
		}

		setCreateIngredientLoading(false)
	}, [deletingIngredient])

	return (
		<div style={{ ...rootView, overflow: 'scroll', padding: 20 }}>
			<TableContainer component={Paper}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
							<TableCell align="right">
								<CButton label="Nuovo ingrediente" onClick={openCreateIngredientModal} />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(displayIngredients || []).map((ingredient) => (
							<TableRow key={ingredient.id}>
								<TableCell component="th" scope="row">
									{ingredient.it}
								</TableCell>
								<TableCell style={{ width: 200 }} component="th" scope="row">
									<CButton style={{ marginRight: 10 }} label="Elimina" onClick={() => { setDeletingIngredient(ingredient) }} />
									<CButton label="Modifica" onClick={() => { openUpdateIngredientModal(ingredient) }} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				style={{ minHeight: 50 }}
				component="div"
				rowsPerPageOptions={[DEFAULT_PAGE]}
				count={pagination.count}
				rowsPerPage={pagination.rows}
				page={pagination.page}
				onPageChange={(e, page) => {
					pagination.setPage(page)
					showItems(ingredients, DEFAULT_PAGE, page)
				}}
				onRowsPerPageChange={(e) => {

				}}
			/>
			<Modal /** Creazione e modifica */
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={createIngredientModalOpen}
				onClose={closeIngredientModal}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 400 }}>
						<div style={{ ...modalTitle }}>{editingIngredient ? "Modifica ingrediente" : "Nuovo ingrediente"}</div>
						<CTextField value={newIngredientLabel} style={{ width: 400 }} placeholder={"Nome dell'ingrediente"} onChange={onCreateIngredientLabelChange} />
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Annulla" onClick={closeIngredientModal} />
							<CButton loading={createIngredientLoading} label={editingIngredient ? "Modifica" : "Crea"} onClick={onSaveIngredientConfirm} />
						</div>
					</div>
				</div>
			</Modal>
			<Modal /** Eliminazione */
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={!!deletingIngredient}
				onClose={closeDeleteModal}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 450 }}>
						<div style={{ ...modalTitle }}>Conferma eliminazione</div>
						<div>{`Sei sicuro di voler eliminare l'ingrediente "${deletingIngredient?.it}"? Verranno eliminati tutti i collegamenti con prodotti esistenti.`}</div>
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Annulla" onClick={closeDeleteModal} />
							<CButton loading={createIngredientLoading} label={"Elimina"} onClick={onDeleteIngredientConfirm} />
						</div>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default IngredientsPage

const styles: ItemsMap<CSSProperties> = {

}