import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { CSSProperties, useCallback, useMemo, useState } from 'react';
//@ts-ignore
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { RequestResponse } from '../shoppinga-client-common/Network';
import { colors } from '../shoppinga-client-common/util';
import MultiSelect, { DisplayItem } from './MultiSelect';

type Props<T, P> = {
	style?: CSSProperties
	placeholder?: string
	index?: number //Serve per lo zindex
	value?: T | null
	data_key: string
	hide_value?: boolean
	mapping: (item: T) => DisplayItem
	request: (query: string) => Promise<RequestResponse<P>>
	onItemSelected: (item: T) => void
	onClear?: () => void
}

const AutoComplete = <T extends unknown, P extends unknown>(props: Props<T, P>) => {

	const self: {
		search: string
		selectedValue: T | null
	} = useMemo(() => {
		return {
			search: "",
			selectedValue: null
		}
	}, [])
	const [items, setItems] = useState<T[]>([])

	const fetchData = useCallback(async (text: string) => {
		const response = await props.request(text)
		if (response.success) {
			//@ts-ignore
			setItems(response.data?.[props.data_key])
		}
	}, [props.request, self])

	const onSearch = useCallback((text: string) => {
		self.search = text
		
		if (!text) {
			
		} else {
			setTimeout(() => {
				if (text == self.search) {
					fetchData(text)
				}
			}, 500)
		}
	}, [self])

	const onSelect = useCallback((item: T) => {
		self.selectedValue = item
		props.onItemSelected(item)
	}, [props.onItemSelected, items, self])
	
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div style={{ ...props.style, flex: 1 }}>
				<MultiSelect<T>
					inputValue={self.search}
					items={items}
					value={props.value ? [props.value] : []}
					mapping={props.mapping}
					onSearch={onSearch}
					isMulti={false}
					onChange={(selected) => {
						if (selected) onSelect(selected[0])
					}}
				/>
			</div>
			{
				props.value && !props.hide_value && <div style={{ padding: "8px 20px 8px 20px", backgroundColor: colors.primary, color: colors.white, fontWeight: 'bold', borderRadius: 20, marginLeft: 10 }}>
					{props.mapping(props.value).label}
				</div>
			}
		</div>
	)
}

export default AutoComplete