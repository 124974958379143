import { http } from "."
import { loadUrl } from "../shoppinga-client-common/Network"
import { ID } from "../shoppinga-common/Models/Generic"
import { Offer } from "../shoppinga-common/Models/Offer"
import { Product } from "../shoppinga-common/Models/Product"

export type CreateOfferPayload = {
	product_id?: ID
	new_product?: Partial<Product>
	offer?: Partial<Offer>
}
export type OfferResponse = {
	offer: Offer
}
export const createOffer = (payload: CreateOfferPayload) => {
	return http<OfferResponse>('offers', 'POST', payload)
}

export const editOffer = (offer_id: ID, payload: CreateOfferPayload) => {
	return http<OfferResponse>(`offers/${offer_id}`, 'PUT', payload)
}

export const deleteOffer = (offer_id: ID) => {
	return http<any>(`offers/${offer_id}`, 'DELETE')
}

export type GetOffersParams = {
	limit?: number
	offset?: number
	query?: string
	shops?: ID[]
	sort_from_date_mode?: 'asc' | 'desc'
}
export type GetOffersResponse = {
	offers: Offer[]
	rows: number
	count: number
}
export const getOffers = (params: GetOffersParams) => {
	const url = loadUrl('offers', params)
	return http<GetOffersResponse>(url)
}

export type GetOfferResponse = {
	offer: Offer
}
export const getOffer = (offer_id: ID) => {
	return http<GetOfferResponse>(`offers/${offer_id}`)
}

export const updateOfferShopBranchesLinks = (offer_id: ID, excluded_shop_branches: number[]) => {
	return http<OfferResponse>(`offers/${offer_id}/shop-branches-links`, "PUT", {
		excluded_shop_branches
	})
}