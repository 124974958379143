export type HTTPMethod = 'POST' | 'GET' | 'PUT' | 'DELETE'

export type RequestResponse<T> = {
	success: boolean,
	auth_expired?: boolean
	user_locked?: boolean
	data: T | null
	error?: string
}

export type JWT = string | null

export const http = async <T extends unknown>(api_url: string, jwt: string | null, url: string, method: HTTPMethod = 'GET', body?: any): Promise<RequestResponse<T>> => {

	try {
		const requestOptions = {
			headers: {
				'Auth': 'Bearer ' + jwt,
				'Content-Type': 'application/json',
			},
			method: method,
			body: body ? JSON.stringify(body) : undefined
		}
		console.log("REQUESTING TO:", api_url + url)
		const response = await fetch(api_url + url, requestOptions)
		return handleServerResponse(response)

	} catch (e: any) {
		console.log(e)
		return {
			success: false,
			data: null,
			error: e
		}
	}
}

export const handleServerResponse = async <T extends unknown>(response: Response): Promise<RequestResponse<T>> => {
	if (response.status == 403) {
		return {
			success: false,
			auth_expired: true,
			data: null
		}
	} else if (response.status == 423) {
		return {
			success: false,
			user_locked: true,
			data: null
		}
	} else {
		const data = await response.json()
		data.success = response.status >= 200 && response.status < 300
		return data
	}
}

const objToQueryString = (obj: any) => {
	const keyValuePairs = [];
	for (const key in obj) {
		if (obj[key] != undefined || obj[key] != null) {
			let val = obj[key]
			
			if(Array.isArray(val)) {
				val = obj[key].join(',')
			}

			keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(val));
		}
	}
	return keyValuePairs.join('&');
}

export const loadUrl = (url: string, queryParams?: any) => {
	let result = url
	if (queryParams) {
		const stringifiedParams = objToQueryString(queryParams)
		result += ('?' + stringifiedParams)
	}

	return result
}