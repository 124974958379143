import React, { useCallback, useState } from 'react';
import { login, saveSessionInfo } from '@Network/auth';
import PCButton from '@Components/CButton';
import PCTextField from '@Components/CTextField';
import { useHistory } from 'react-router-dom';
import { rootView } from '@Global/styles';
import { webPath } from 'src/App';
import { errorMessage, genericError, GENERIC_ERROR_MESSAGE } from '../Global/util';
import { useToasts } from 'react-toast-notifications';

type Props = {
	
}

const Login = (props: Props) => {

	const history = useHistory()
	const { addToast } = useToasts();
	
	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")

	const onEmailChange = useCallback((text) => {
		setEmail(text)
	}, [])

	const onPasswordChange = useCallback((text) => {
		setPassword(text)
	}, [])

	const onLoginPress = useCallback(async() => {
		try {
			setLoading(true)

			const response = await login(email, password)
			if(response.success) {
				const user = response.data?.user
				const jwt = response.data?.jwt
				if(user?.role == 'user') {
					errorMessage("Tipologia di utente non supportata", addToast)
				} else if(user?.role == 'admin') {
					saveSessionInfo(user!, jwt!)
					history.push(webPath('/admin/products'))
				}
			} else {
				genericError(addToast)
			}
		} catch(e: any) {

			let message = GENERIC_ERROR_MESSAGE
			if (e.code === 'auth/wrong-password') {
				message = "Credenziali errate"
			} else if (e.code === 'auth/invalid-email') {
				message = "Email non valida"
			} else if (e.code === 'auth/user-not-found') {
				message = "Utente non trovato"
			}

			errorMessage(message, addToast)
		}
		setLoading(false)
	}, [email, password])

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...rootView}}>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: 300, backgroundColor: '#fff', padding: 40, borderRadius: 5, marginTop: 100 }}>
				<div style={{ alignSelf: 'center', fontWeight: 'bold', fontSize: 20, marginBottom: 20 }}>Login</div>
				<PCTextField placeholder="Email" onChange={onEmailChange}/>
				<PCTextField type="password" placeholder="Password" style={{ marginTop: 10 }} onChange={onPasswordChange}/>
				<PCButton loading={loading} style={{ marginTop: 20 }} onClick={onLoginPress} label="Login"/>
			</div>
		</div>
	)
}

export default Login