import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { genericError, ItemsMap, successMessage } from '@Global/util';
import { useHistory } from 'react-router-dom';
import { modalActionsContainer, modalContainer, modalTitle, rootView } from '@Global/styles';
import CTextField from '@Components/CTextField';
import CButton from '@Components/CButton';
import { ProductCategory } from '../shoppinga-common/Models/Product';
import { createCategory, deleteCategory, editProduct, getCategories, updateCategory } from '../Network/products';
import { TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Modal } from '@material-ui/core';
import 'moment/locale/it'
import { useToasts } from 'react-toast-notifications';

type Props = {

}

const CategoriesPage = (props: Props) => {

	const history = useHistory()
	const { addToast } = useToasts();

	const [categories, setCategories] = useState<ProductCategory[]>([])
	
	const [createCategoryModalOpen, setCreateCategoryModalOpen] = useState(false)
	const [newCategoryLabel, setNewCategoryLabel] = useState("")
	const [createCategoryLoading, setCreateCategoryLoading] = useState(false)
	const [editingCategory, setEditingCategory] = useState<ProductCategory>()

	const [deletingCategory, setDeletingCategory] = useState<ProductCategory>()

	useEffect(() => {
		fetchCategories()
	}, [])

	const fetchCategories = useCallback(async () => {
		const response = await getCategories("")
		if (response.success) {
			setCategories(response.data?.categories || [])
		} else {
			genericError(addToast)
		}
	}, [])

	const openCreateCategoryModal = useCallback(() => {
		setCreateCategoryModalOpen(true)
		setNewCategoryLabel("")
	}, [])

	const openUpdateCategoryModal = useCallback((category: ProductCategory) => {
		setEditingCategory(category)
		setNewCategoryLabel(category.it)
		setCreateCategoryModalOpen(true)
	}, [])

	const closeCategoryModal = useCallback(() => {
		setEditingCategory(undefined)
		setNewCategoryLabel("")
		setCreateCategoryModalOpen(false)
	}, [])

	const closeDeleteModal = useCallback(() => {
		setDeletingCategory(undefined)
	}, [])

	const onCreateCategoryLabelChange = useCallback((text: string) => {
		setNewCategoryLabel(text)
	}, [])

	const onSaveCategoryConfirm = useCallback(async () => {
		setCreateCategoryLoading(true)

		let response
		if(editingCategory) {
			response = await updateCategory(editingCategory.id, {
				label: newCategoryLabel
			})
		} else {
			response = await createCategory(newCategoryLabel)
		}
		if(response.success) {
			if(editingCategory) {
				successMessage("Categoria modificata con successo", addToast)
			} else {
				successMessage("Categoria creata con successo", addToast)
			}
			fetchCategories()
			closeCategoryModal()
		} else {
			genericError(addToast)
		}

		setCreateCategoryLoading(false)
	}, [newCategoryLabel, editingCategory])

	const onDeleteCategoryConfirm = useCallback(async () => {
		setCreateCategoryLoading(true)

		const response = await deleteCategory(deletingCategory!.id)
		if(response.success) {
			successMessage("Categoria eliminata con successo", addToast)
			fetchCategories()
			closeDeleteModal()
		} else {
			genericError(addToast)
		}

		setCreateCategoryLoading(false)
	}, [deletingCategory])

	return (
		<div style={{ ...rootView, overflow: 'scroll', padding: 20 }}>
			<TableContainer component={Paper}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
							<TableCell align="right">
								<CButton label="Nuova categoria" onClick={openCreateCategoryModal} />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(categories || []).map((category) => (
							<TableRow key={category.id}>
								<TableCell component="th" scope="row">
									{category.it}
								</TableCell>
								<TableCell style={{ width: 200 }} component="th" scope="row">
									<CButton style={{ marginRight: 10 }} label="Elimina" onClick={() => { setDeletingCategory(category) }} />
									<CButton label="Modifica" onClick={() => { openUpdateCategoryModal(category) }} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Modal /** Creazione e modifica */
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={createCategoryModalOpen}
				onClose={closeCategoryModal}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 400 }}>
						<div style={{ ...modalTitle }}>{editingCategory ? "Modifica categoria" : "Nuova categoria"}</div>
						<CTextField value={newCategoryLabel} style={{ width: 400 }} placeholder={"Nome della categoria"} onChange={onCreateCategoryLabelChange} />
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Annulla" onClick={closeCategoryModal} />
							<CButton loading={createCategoryLoading} label={editingCategory ? "Modifica" : "Crea"} onClick={onSaveCategoryConfirm} />
						</div>
					</div>
				</div>
			</Modal>
			<Modal /** Eliminazione */
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={!!deletingCategory}
				onClose={closeDeleteModal}
			>
				<div style={{ display: 'flex', marginTop: 300, justifyContent: 'center' }}>
					<div style={{ ...modalContainer, maxWidth: 450 }}>
						<div style={{ ...modalTitle }}>Conferma eliminazione</div>
						<div>{`Sei sicuro di voler eliminare la categoria "${deletingCategory?.it}"? Verranno eliminati tutti i collegamenti con prodotti esistenti.`}</div>
						<div style={{ ...modalActionsContainer }}>
							<CButton style={{ marginRight: 10 }} label="Annulla" onClick={closeDeleteModal} />
							<CButton loading={createCategoryLoading} label={"Elimina"} onClick={onDeleteCategoryConfirm} />
						</div>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default CategoriesPage

const styles: ItemsMap<CSSProperties> = {

}