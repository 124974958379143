import Button from '@material-ui/core/Button';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { CSSProperties, ForwardedRef, ForwardRefRenderFunction, ReactElement, Ref, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Allergen, Ingredient, Product, ProductCategory, ProductPlace, ProductStatus, statusString } from '@Common/Models/Product';
import CTextField from './CTextField';
import { genericError, ItemsMap, successMessage } from '../Global/util';
import CButton from './CButton';
import { DEFAULT_UNIT, Unit, unitOptions } from '../shoppinga-common/Models/List';
import { colors } from '../shoppinga-client-common/util';
import AutoComplete from './AutoComplete';
import { approveProduct, getAllergens, getCategories, GetCategoriesResponse, getIngredients, GetIngredientsResponse, getPlaces, GetPlacesResponse, rejectProduct } from '../Network/products';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MultiSelect from './MultiSelect';
import { MultiValue } from 'react-select';
import { useToasts } from 'react-toast-notifications';

export type ProductInputRef = {
	getProductInfo: () => Partial<Product> & { photo: any }
}

type Props = {
	product?: Product | null
	saveLoading?: boolean
	hideActions?: boolean
	onStatusChange?: (newStatus: ProductStatus) => void
	onSave?: (data: Partial<Product> & { openNew?: boolean }, image?: string) => void
} & { ref?: Ref<ProductInputRef> | null }

type TextInputProps = {
	title: string
	multiline?: boolean
	minWidth?: number
	maxWidth?: number
	field: string
	setter: React.Dispatch<React.SetStateAction<string>>
}
const TextInput = ({ title, multiline, minWidth, maxWidth, field, setter }: TextInputProps) => {
	return (
		<div style={styles.inputContainer}>
			<div style={styles.textTitle}>{title}</div>
			<CTextField multiline={multiline} style={{ minWidth, maxWidth }} value={field} onChange={setter} />
		</div>
	)
}

const ProductInput: ForwardRefRenderFunction<ProductInputRef, Props> = (props: Props, ref: ForwardedRef<ProductInputRef>) => {

	const { addToast } = useToasts();

	const nutriments = useMemo(() => {
		return [
			{ name: "Energia", key: 'kcal' },
			{ name: "Proteine", key: 'proteins' },
			{ name: "Carboidrati", key: 'carbohydrates' },
			{ name: "Zuccheri", key: 'sugars' },
			{ name: "Grassi", key: 'fat' },
			{ name: "Grassi saturi", key: 'saturated_fat' },
			{ name: "Fibre", key: 'fiber' },
			{ name: "Sale", key: 'salt' },
			{ name: "Vitamina a", key: 'vitamin_a' },
			{ name: "Vitamina b1", key: 'vitamin_b1' },
			{ name: "Vitamina b2", key: 'vitamin_b2' },
			{ name: "Vitamina b6", key: 'vitamin_b6' },
			{ name: "Vitamina b9", key: 'vitamin_b9' },
			{ name: "Vitamina b12", key: 'vitamin_b12' },
			{ name: "Vitamina c", key: 'vitamin_c' },
			{ name: "Vitamina d", key: 'vitamin_d' },
			{ name: "Vitamina e", key: 'vitamin_e' },
			{ name: "Vitamina k", key: 'vitamin_k' },
			{ name: "Vitamina pp", key: 'vitamin_pp' },
			{ name: "Biotina", key: 'biotin' },
			{ name: "Potassio", key: 'potassium' },
			{ name: "Ferro", key: 'iron' },
			{ name: "Fosforo", key: 'phosphorus' },
			{ name: "Calcio", key: 'calcium' },
			{ name: "Magnesio", key: 'magnesium' },
			{ name: "Cloro", key: 'chloride' },
			{ name: "Zinco", key: 'zinc' },
			{ name: "Selenio", key: 'selenium' },
			{ name: "Iodio", key: 'iodine' },
			{ name: "Sodio", key: 'sodium' },
		]
	}, [])

	const [categories, setCategories] = useState<ProductCategory[]>([])
	const [places, setPlaces] = useState<ProductPlace[]>([])
	const [allergens, setAllergens] = useState<Allergen[]>([])
	const [ingredients, setIngredients] = useState<Ingredient[]>([])
	const [displayIngredients, setDisplayIngredients] = useState<Ingredient[]>([])

	const [name, setName] = useState("")
	const [brand, setBrand] = useState("")
	const [ean, setEan] = useState("")
	const [description, setDescription] = useState("")
	const [preparation, setPreparation] = useState("")
	const [conservation, setConservation] = useState("")
	const [quantity, setQuantity] = useState("")
	const [selectedUnit, setSelectedUnit] = useState<Unit>(DEFAULT_UNIT)
	const [rightQtaNumItems, setRightQtaNumItems] = useState("1")
	const [rightQtaQuantity, setRightQtaQuantity] = useState("")
	const [rightQtaUnit, setRightQtaUnit] = useState<Unit>(DEFAULT_UNIT)
	const [verified, setVerified] = useState(false)
	const [selectedCategories, setSelectedCategories] = useState<ProductCategory[]>([])
	const [selectedPlaces, setSelectedPlaces] = useState<ProductPlace[]>([])
	const [selectedAllergens, setSelectedAllergens] = useState<Allergen[]>([])
	const [selectedIngredients, setSelectedIngredients] = useState<Ingredient[]>([])
	const [displayPhoto, setDisplayPhoto] = useState<string | null>(null)
	const [photo, setPhoto] = useState<string | null>(null)
	const [nutrimentsValues, setNutrimentsValues] = useState<any>({})

	const inputFile = useRef(null)

	useEffect(() => {
		const fetchCategories = async () => {
			const response = await getCategories("")
			if (response.success) {
				setCategories(response.data?.categories || [])
			}
		}

		const fetchPlaces = async () => {
			const response = await getPlaces("")
			if (response.success) {
				setPlaces(response.data?.places || [])
			}
		}

		const fetchAllergens = async () => {
			const response = await getAllergens()
			if (response.success) {
				setAllergens(response.data?.allergens || [])
			}
		}

		const fetchIngredients = async () => {
			const response = await getIngredients("", true)
			if (response.success) {
				setIngredients(response.data?.ingredients || [])
			}
		}

		fetchCategories()
		fetchPlaces()
		fetchAllergens()
		fetchIngredients()
	}, [])

	useEffect(() => {
		setName(props.product?.name || "")
		setDescription(props.product?.description || "")
		setPreparation(props.product?.preparation || "")
		setConservation(props.product?.conservation || "")
		setBrand(props.product?.brand || "")
		setEan(props.product?.ean_code || "")
		setQuantity(props.product?.quantity || "")
		setSelectedUnit(props.product?.unit || DEFAULT_UNIT)
		setVerified(props.product?.verified || false)
		setRightQtaNumItems(props.product?.right_qta_num_items?.toString() || "1")
		setRightQtaQuantity(props.product?.right_qta_quantity?.toString() || "")
		setRightQtaUnit(props.product?.right_qta_unit || DEFAULT_UNIT)
		setDisplayPhoto(props.product?.image || null)
		setSelectedCategories(props.product?.categories || [])
		setSelectedPlaces(props.product?.places || [])
		setSelectedAllergens(props.product?.allergens || [])
		setSelectedIngredients(props.product?.ingredients || [])
		onIngredientsSearch("")

		setNutrimentsValues(nutriments.map((n) => {
			return {
				key: n.key,
				//@ts-ignore
				value: props.product?.[n.key]
			}
		}).reduce((acc, item) => {
			return {
				...acc,
				[item.key]: item.value
			}
		}, {}))
	}, [props.product])

	const onApproveClick = useCallback(() => {
		editProductStatus(props.product!, ProductStatus.Approved)
	}, [props.product])

	const onRejectClick = useCallback(() => {
		editProductStatus(props.product!, ProductStatus.Rejected)
	}, [props.product])

	const editProductStatus = useCallback(async (product: Product, status: ProductStatus) => {
		let response;
		if (status == ProductStatus.Approved) {
			response = await approveProduct(product.id)
		} else {
			response = await rejectProduct(product.id)
		}

		if (response.success) {
			successMessage("Operazione completata", addToast)
			props.onStatusChange?.(status)
		} else {
			genericError(addToast)
		}
	}, [props.product])

	const onSaveClick = useCallback((openNew: boolean) => {
		props.onSave?.({
			openNew,
			...getProductInfo()
		}, !!photo ? photo : undefined)
	}, [
		name, brand, ean, quantity, selectedUnit, verified, description, preparation, conservation,
		rightQtaNumItems, rightQtaQuantity, rightQtaUnit,
		photo, nutrimentsValues,
		selectedCategories, selectedPlaces, selectedAllergens, selectedIngredients
	])

	const onChooseImageClicked = useCallback(() => {
		//@ts-ignore
		inputFile.current?.click()
	}, [inputFile])

	const onImageChange = useCallback((event) => {
		if (event.target.files && event.target.files[0]) {
			setDisplayPhoto(URL.createObjectURL(event.target.files[0]))
			setPhoto(event.target.files[0])
		}
	}, [])

	const onPaste = useCallback((e) => {
		var item = e.clipboardData.items[0];

		if (item.type.indexOf("image") === 0) {
			var blob = item.getAsFile();
			setPhoto(blob)
			setDisplayPhoto(URL.createObjectURL(blob))
		}
	}, [])

	const getProductInfo = (): Partial<Product> & { photo: any } => {
		return {
			name,
			brand,
			ean_code: ean,
			description,
			preparation,
			conservation,
			quantity,
			unit: selectedUnit,
			verified,
			categories: selectedCategories.map(c => c.id),
			places: selectedPlaces.map(p => p.id),
			allergens: selectedAllergens.map(a => a.id),
			ingredients: selectedIngredients.map(i => i.id),
			...nutrimentsValues,
			photo,
			right_qta_num_items: rightQtaNumItems,
			right_qta_quantity: rightQtaQuantity,
			right_qta_unit: rightQtaUnit
		}
	}

	if (ref && typeof ref == 'object') {
		ref.current = { getProductInfo }
	}

	const onIngredientsSearch = (text: string) => {
		const result = ingredients.filter(i => i.it.includes(text)).slice(0, 20)
		setDisplayIngredients(result)
	}

	const mergeSelectedAndDisplayIngredients = () => {
		const result = displayIngredients
		selectedIngredients.forEach((i) => {
			if(!result.find((_i) => _i.id === i.id)) {
				result.push(i)
			}
		})
		return result
	}

	return (
		<div onPaste={onPaste}>
			{!props.hideActions && <div style={{ ...styles.inputContainer, ...styles.textTitle }}>Info Prodotto</div>}
			<div style={styles.rowWrap}>
				<TextInput title="Nome" minWidth={350} field={name} setter={setName} />
				<TextInput title="Brand" field={brand} setter={setBrand} />
				<TextInput title="Ean" field={ean} setter={setEan} />
			</div>

			<div style={{ ...styles.rowWrap, alignItems: 'flex-start' }}>
				<TextInput title="Descrizione" multiline minWidth={350} field={description} setter={setDescription} />
				<TextInput title="Preparazione" multiline minWidth={350} field={preparation} setter={setPreparation} />
				<TextInput title="Conservazione" multiline minWidth={350} field={conservation} setter={setConservation} />
			</div>

			<div style={styles.rowWrap}>
				<TextInput title="Formato testuale" field={quantity} setter={setQuantity} />

				<FormControl style={styles.inputContainer}>
					<div style={styles.textTitle}>Unità</div>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={selectedUnit}
						label="Age"
						onChange={(e) => {
							setSelectedUnit(e.target.value as Unit)
						}}
					>
						{unitOptions.map(item => {
							return (
								<MenuItem value={item.value} key={item.value}>{item.value}</MenuItem>
							)
						})}
					</Select>
				</FormControl>
			</div>

			<div style={styles.rowWrap}>
				<TextInput maxWidth={60} title="Numero" field={rightQtaNumItems} setter={setRightQtaNumItems} />
				<div style={{ marginLeft: 10, marginRight: 10, marginTop: 20, fontWeight: 'bold' }}>X</div>
				<TextInput title="Formato" field={rightQtaQuantity} setter={setRightQtaQuantity} />

				<FormControl style={styles.inputContainer}>
					<div style={styles.textTitle}>Unità</div>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={rightQtaUnit}
						label="Age"
						onChange={(e) => {
							setRightQtaUnit(e.target.value as Unit)
						}}
					>
						{unitOptions.map(item => {
							return (
								<MenuItem value={item.value} key={item.value}>{item.value}</MenuItem>
							)
						})}
					</Select>
				</FormControl>
			</div>

			<div style={styles.rowWrap}>
				<div style={{ ...styles.inputContainer }}>
					<div style={{ ...styles.textTitle, marginBottom: 10 }}>Categoria</div>
					<MultiSelect<ProductCategory>
						items={categories}
						isMulti
						mapping={i => ({ value: i.id, label: i.it })}
						onChange={(selected) => {
							setSelectedCategories(selected)
						}}
						value={selectedCategories}
					/>
				</div>

				<div style={{ ...styles.inputContainer }}>
					<div style={{ ...styles.textTitle, marginBottom: 10 }}>Reparto</div>
					<MultiSelect<ProductPlace>
						items={places}
						width={500}
						isMulti
						mapping={i => ({ value: i.id, label: i.it })}
						onChange={(selected) => {
							setSelectedPlaces(selected)
						}}
						value={selectedPlaces}
					/>
				</div>
			</div>

			<div style={{ ...styles.rowWrap }}>
				<div style={{ ...styles.inputContainer }}>
					<div style={{ ...styles.textTitle, marginBottom: 10 }}>Allergeni</div>
					<MultiSelect<Allergen>
						items={allergens}
						isMulti
						mapping={i => ({ value: i.id, label: i.it })}
						onChange={(selected) => {
							setSelectedAllergens(selected)
						}}
						value={selectedAllergens}
					/>
				</div>
				<div style={{ ...styles.inputContainer }}>
					<div style={{ ...styles.textTitle, marginBottom: 10 }}>Ingredienti</div>
					<MultiSelect<Ingredient>
						items={mergeSelectedAndDisplayIngredients()}
						onSearch={onIngredientsSearch}
						isMulti
						mapping={i => ({ value: i.id, label: i.it })}
						onChange={(selected) => {
							setSelectedIngredients(selected)
						}}
						value={selectedIngredients}
					/>
				</div>
			</div>

			<div style={{ ...styles.inputContainer, display: 'flex' }}>
				<div>
					<div style={styles.textTitle}>Foto</div>
					<div style={{ ...styles.photoContainerDimensions, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
						{
							displayPhoto ?
								<img style={{ ...styles.photoDimensions }} src={displayPhoto} />
								:
								<img style={{ ...styles.photoDimensions }} src={'/assets/image-placeholder.png'} />
						}
					</div>
				</div>
				<input ref={inputFile} style={{ display: 'none' }} type="file" onChange={onImageChange} className="filetype" />
				<CButton style={{ alignSelf: 'flex-end', marginLeft: 20 }} label="Carica" onClick={onChooseImageClicked} />
			</div>

			{props.product && <div style={{
				...styles.inputContainer,
				display: 'flex',
				alignItems: 'center',
				marginTop: 30
			}}>
				<div>
					<div style={{
						...styles.textTitle,
					}}>Stato</div>
					<div>{statusString(props.product.status)}</div>
				</div>
				{props.product.status == ProductStatus.Pending && !props.hideActions && <div style={{ marginLeft: 10 }}>
					<CButton style={{ marginRight: 10 }} label="Approva" onClick={onApproveClick} />
					<CButton label="Rifiuta" onClick={onRejectClick} />
				</div>}
				<div style={{ marginLeft: 40 }}>
					<FormGroup>
						<FormControlLabel
							onChange={(_, checked) => setVerified(checked)}
							control={<Checkbox checked={verified} color={'primary'} />}
							label="Verificato"
						/>
					</FormGroup>
				</div>
			</div>}

			<div style={{ marginLeft: 10, marginRight: 10, marginTop: 20 }}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<div style={{ ...styles.textTitle }}>Valori nutrizionali</div>
					</AccordionSummary>
					<AccordionDetails>
						<div style={styles.rowWrap}>
							{nutriments.map((n) => {
								return <div style={{ marginRight: 10, marginTop: 10 }}>
									<div>{n.name}</div>
									<CTextField
										key={n.key}
										value={nutrimentsValues[n.key] || ""}
										onChange={(text: string) => {
											setNutrimentsValues({
												...nutrimentsValues,
												[n.key]: text.split(",").join(".") || null
											})
										}}
									/>
								</div>
							})}
						</div>
					</AccordionDetails>
				</Accordion>
			</div>

			{!props.hideActions && <div style={{
				...styles.inputContainer,
				marginTop: 50
			}}>
				<CButton loading={props.saveLoading} style={{ marginRight: 10 }} label="Salva prodotto" onClick={() => {
					onSaveClick(false)
				}} />
				<CButton loading={props.saveLoading} style={{ marginRight: 10 }} label="Salva e nuovo" onClick={() => {
					onSaveClick(true)
				}} />
			</div>}

		</div>
	)
}

const ProductInputWithRef = React.forwardRef(ProductInput)

export default ProductInputWithRef

const styles: ItemsMap<CSSProperties> = {
	inputContainer: {
		margin: 10
	},
	textTitle: {
		fontWeight: 'bold'
	},
	rowWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	photoContainerDimensions: {
		width: 120,
		height: 120,
		backgroundColor: colors.white,
		borderRadius: 10
	},
	photoDimensions: {
		maxWidth: 100,
		maxHeight: 100
	}
}