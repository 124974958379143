import { GetProductsParams, 
	getProducts as _getProducts, 
	getProduct as _getProduct,
	editProduct as _editProduct,
	createProduct as _createProduct,
	deleteProduct as _deleteProduct,
	getAllergens as _getAllergens
} from "@ClientCommon/Network/products"
import config from "../config"
import { loadJWT } from "../Global/local.storage"
import { ID } from "@Common/Models/Generic"
import { Allergen, Ingredient, Product, ProductCategory, ProductPlace, ProductStatus } from "../shoppinga-common/Models/Product"
import { loadUrl } from "../shoppinga-client-common/Network"
import { http } from "./index"

export const getProducts = async (params: GetProductsParams) => {
	return _getProducts(config.api_url, loadJWT(), params)
}

export const getProduct = async (product_id: ID) => {
	return _getProduct(config.api_url, loadJWT(), product_id)
}

export const editProduct = (product_id: ID, data: Partial<Product>) => {
	return _editProduct(config.api_url, loadJWT(), product_id, data)
}

export const approveProduct = (product_id: ID) => {
	return editProduct(product_id, { status: ProductStatus.Approved })
}

export const rejectProduct = (product_id: ID) => {
	return editProduct(product_id, { status: ProductStatus.Rejected })
}

export const createProduct = (user_id: ID, data: Partial<Product>) => {
	return _createProduct(config.api_url, loadJWT(), user_id, data)
}

export const deleteProduct = (product_id: ID) => {
	return _deleteProduct(config.api_url, loadJWT(), product_id)
}

export const deleteProductsBulk = (product_ids: ID[]) => {
	console.log(product_ids)
	const url = loadUrl('products/bulk', {
		ids: product_ids.join(',')
	})
	return http<any>(url, 'DELETE')
}

export type CreateCategoryResponse = {
	category: ProductCategory
}
export const createCategory = (label: string) => {
	return http<CreateCategoryResponse>('categories', 'POST', {
		label
	})
}

export const updateCategory = (category_id: ID, data: { label: string }) => {
	return http<CreateCategoryResponse>(`categories/${category_id}`, 'PUT', data)
}

export const deleteCategory = (category_id: ID) => {
	return http<any>(`categories/${category_id}`, 'DELETE')
}

export type GetCategoriesResponse = {
	categories: ProductCategory[]
}
export const getCategories = (query: string) => {
	const url = loadUrl('categories', {
		query
	})
	return http<GetCategoriesResponse>(url)
}

export type GetIngredientsResponse = {
	ingredients: Ingredient[]
}
export const getIngredients = (query: string, order_by_length?: boolean) => {
	const url = loadUrl('ingredients', {
		query,
		order_by_length
	})
	return http<GetIngredientsResponse>(url)
}

export type CreateIngredientResponse = {
	ingredient: Ingredient
}
export const createIngredient = (label: string) => {
	return http<CreateIngredientResponse>('ingredients', 'POST', {
		label
	})
}

export const updateIngredient = (ingredient_id: ID, data: { label: string }) => {
	return http<CreateIngredientResponse>(`ingredients/${ingredient_id}`, 'PUT', data)
}

export const deleteIngredient = (ingredient_id: ID) => {
	return http<any>(`ingredients/${ingredient_id}`, 'DELETE')
}

export type GetPlacesResponse = {
	places: ProductPlace[]
}
export const getPlaces = (query: string) => {
	const url = loadUrl('places', {
		query
	})
	return http<GetPlacesResponse>(url)
}

export const getAllergens = () => {
	return _getAllergens(config.api_url, loadJWT())
}