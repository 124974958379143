import { UserRole } from "@Common/Models/User"

export const localSave = (key: string, value: any) => {
	if (typeof value == 'object') {
		value = JSON.stringify(value)
	}

	localStorage.setItem(key, value)
}

export const localGet = (key: string) => {
	const value = localStorage.getItem(key)
	if(!value) return null
	try {
		return JSON.parse(value)
	} catch(e) {
		return value
	}
}

export const saveJWT = (token: string | null) => {
	return localSave('jwt', token)
}

export const loadJWT = () => {
	return localGet('jwt')
}

export const saveUserId = (id: string | null) => {
	return localSave('user_id', id)
}

export const loadUserId = () => {
	return localGet('user_id')
}

export const saveUserRole = (role: UserRole) => {
	return localSave('user_role', role)
}

export const loadUserRole = () => {
	return localGet('user_role')
}